import { Component, ElementRef, Input, NgZone, OnInit, QueryList, ViewChildren, ViewEncapsulation } from '@angular/core';
import { DropdownItem, DropdownProps } from '@profis-engineering/pe-ui-common/components/dropdown/dropdown.common';
import { CodeList, ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Language } from '@profis-engineering/pe-ui-common/entities/code-lists/language';
import { ReportPaperSize } from '@profis-engineering/pe-ui-common/entities/code-lists/report-paper-size';
import { IExportReportCompanyLayoutComponentInput } from '@profis-engineering/pe-ui-common/entities/export-report-company-layout';
import { IExportReportProjectDetailsInput } from '@profis-engineering/pe-ui-common/entities/export-report-project-details';
import { IExportTrimbleConnectComponentInput } from '@profis-engineering/pe-ui-common/entities/export-trimble-connect';
import { ReportTemplateEntity } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate';
import { PaperSize, ReportType, TemplateOptionLocationType, TemplateOptionType } from '@profis-engineering/pe-ui-common/generated-modules/Hilti.PE.DocumentServiceLegacy.Shared.ReportLayoutTemplate.Enums';
import { ModalInstance } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { CommonCodeList } from '@profis-engineering/pe-ui-common/services/common-code-list.common';
import { LanguageCulture, LocalizationServiceBase } from '@profis-engineering/pe-ui-common/services/localization.common';
import { environment } from '../../../environments/environment';
import { BrowserService } from '../../services/browser.service';
import { CommonCodeListService } from '../../services/common-code-list.service';
import { DataService } from '../../services/data.service';
import { ApiDesignReportGenerateOptions, CalculateResultReport, DesignService, designTypes, designTypeSwitch, PropertyIdValue, PunchCalculateResultReport, PunchCalculationResult, ScopeCheckResult, ScopeCheckSeverity, StrengthCalculateResultReport, StrengthCalculationResult, TemplateOptions, TranslatableParameter, UploadCustomImageResponse } from '../../services/design.service';
import { LocalizationService } from '../../services/localization.service';
import { ModalService } from '../../services/modal.service';
import { NumberService } from '../../services/number.service';
import { ReportTemplateService } from '../../services/report-template.service';
import { SpApiService } from '../../services/sp-api.service';
import { UnitService } from '../../services/unit.service';
import { UserSettingsService } from '../../services/user-settings.service';
import { UserService } from '../../services/user.service';
import { includeSprites, productDialogSprites } from '../../sprites';
import { ScreenshotSettings } from '../../web-gl/gl-model';
import { ApprovalsService } from '../../services/approval.service';
import { CultureInfoService } from '../../services/culture-info.service';
import { TranslationFormatService } from '../../services/translation-format.service';
import { SharedEnvironmentService } from '../../services/shared-environment.service';
import { DocumentService } from '../../services/document.service';

const ReportTemplateId = {
    Default: null,
    Custom: 0
} as { Default: null; Custom: 0 };

export interface IExportReportComponentInput {
    createScreenshot3D: (screenshotSettings: ScreenshotSettings) => Promise<string>;
    propertyChange: (propertyChanges: PropertyIdValue[]) => Promise<void>;
}

interface ISectionCollapse {
    summary: boolean;
    projectDetails: boolean;
    commentAndNotes: boolean;
    layout: boolean;
    trimbleConnect: boolean;
    customPicturesAvailable: boolean;
    calculations: boolean;
}

// TODO: it might come back after production with https://hilti.atlassian.net/browse/PSP-173 and https://hilti.atlassian.net/browse/PSP-210
// enum ReportDetails {
//     IncludeItemNumbers = 1,
//     IncludeApprovals = 2,
// }

enum ScopeCheckId {
    KernelThetaMinAngleNotSatisfied = 1
}

const customTemplateId = Number.MAX_VALUE;

interface IExportReportProjectDetails extends IExportReportProjectDetailsInput {
    isIncludeDetailsInReport: boolean;
}

@Component({
    templateUrl: './export-report.component.html',
    styleUrls: ['./export-report.component.scss'],
    encapsulation: ViewEncapsulation.ShadowDom
})
export class ExportReportComponent implements OnInit {

    /* ====================================== VARIABLES ======================================*/

    @Input()
    public modalInstance!: ModalInstance<IExportReportComponentInput>;

    @ViewChildren('customPicturesInput')
    public customPicturesInputElement!: QueryList<ElementRef>;

    public isLoaded = false;
    public submitted = false;
    public isSectionCollapsed!: ISectionCollapse;

    public strengthDesignTypeId = designTypes.strength.id;
    public punchDesignTypeId = designTypes.punch.id;

    // Comments and notes
    public noteLoads!: string;
    public noteInstallationData!: string;

    public modelImage = '';

    // Project details
    public exportReportProjectDetailsInputs!: IExportReportProjectDetails;
    // Company layout
    public exportReportCompanyLayoutInputs!: IExportReportCompanyLayoutComponentInput;
    // Trimble-connect
    public exportTrimbleConnectInput!: IExportTrimbleConnectComponentInput;

    // Custom pictures
    public customPictures: Map<
        number,
        {
            imgUrl?: string;
            imgSize: number;
            imgId: string;
            updated: boolean;
            position: number;
        }
    > = new Map<
        number,
        {
            imgUrl: string;
            imgSize: number;
            imgId: string;
            updated: boolean;
            position: number;
        }
    >();
    private customPicturesUploadSize!: number;
    private customPicturesChanged!: boolean;
    private readonly DEFAULT_EN_LANGUAGE = 1033;
    //public picturesToUpload: IUploadedPicture[] = [];

    /* ====================================== INITIALIZATION ====================================== */

    constructor(
        public localizationService: LocalizationService,
        private elementRef: ElementRef<HTMLElement>,
        private userService: UserService,
        private userSettingService: UserSettingsService,
        private dataService: DataService,
        private commonCodeListService: CommonCodeListService,
        private modalService: ModalService,
        private ngZone: NgZone,
        private numberService: NumberService,
        private reportTemplateService: ReportTemplateService,
        private spApiService: SpApiService,
        private browserService: BrowserService,
        private designService: DesignService,
        private unitService: UnitService,
        private approvalsService: ApprovalsService,
        private cultureInfoService: CultureInfoService,
        private translationFormatService: TranslationFormatService,
        private sharedEnvironmentService: SharedEnvironmentService,
        private documentService: DocumentService,
    ) { }

    public ngOnInit(): void {
        includeSprites(this.elementRef.nativeElement.shadowRoot,
            'sprite-lines-expanded',
            'sprite-lines',
            'sprite-x',
            'sprite-module-custom-strength',
            ...productDialogSprites
        );

        this.isSectionCollapsed = {
            summary: false,
            commentAndNotes: true,
            layout: true,
            projectDetails: true,
            trimbleConnect: true,
            customPicturesAvailable: true,
            calculations: true,
        };

        this.initModelImage();
        this.initProjectDetailsSection();
        this.initCommentsAndNotesSection();
        this.initCustomImagesSection();
        this.initLayoutSection();
        this.initTrimbleConnectSection();

        this.isLoaded = true;
    }

    /* ====================================== GETTERS ====================================== */

    public get designDetails() {
        return this.userService.design.designDetails;
    }

    public get trackingDetails() {
        return this.userService.design.trackingDetails;
    }

    public get designProperties() {
        return this.designDetails.properties;
    }

    public get formValid() {
        const exportReportCompanyLayoutValid =
            this.exportReportCompanyLayoutInputs.templateDropdown.selectedValue != customTemplateId
            || this.exportReportCompanyLayoutInputs.reportEmailValid;

        return exportReportCompanyLayoutValid;
    }

    public get reportDisabled() {
        // TODO TEAM
        //return this.featuresVisibilityInfoService.isDisabled(Feature.Application_Report, this.design.region.id);
        return false;
    }

    public get exportHtmlReportEnabled() {
        return environment.exportHtmlReport;
    }

    private get customPicturesInputs() {
        return [
            this.customPicturesInputElement.get(0)?.nativeElement as HTMLInputElement,
            this.customPicturesInputElement.get(1)?.nativeElement as HTMLInputElement,
            this.customPicturesInputElement.get(2)?.nativeElement as HTMLInputElement,
        ];
    }

    public get disableTrimbleConnect() {
        // TODO TEAM
        //return !this.trimbleConnectService.isEnabled;
        return false;
    }

    private get reportTemplates() {
        return this.reportTemplateService.templates;
    }

    private get languages() {
        // With https://hilti.atlassian.net/browse/PSP-359 Chinese (Traditional), Chinese (Simplified), Japanese and Korean are disabled/hidden
        // TODO: This might come back later with https://hilti.atlassian.net/browse/PSP-560
        return (this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[]).filter(language => language.culture != LanguageCulture.pseudoLanguage
            && language.culture != 'zh-TW' && language.culture != 'zh-CN' && language.culture != 'ja' && language.culture != 'ko-KR');
    }

    private get defaultLanguage() {
        return (this.commonCodeListService.commonCodeLists[CommonCodeList.Language] as Language[]).find(language => language.culture === 'en-US')!;
    }

    public get reportPaperSizes() {
        return this.commonCodeListService.commonCodeLists[CommonCodeList.ReportPaperSize] as ReportPaperSize[];
    }

    public get reportTypes() {
        // TODO: Only the detailed report is desired right now.
        const allReportTypes = this.commonCodeListService.commonCodeLists[CommonCodeList.ReportType];
        return allReportTypes.filter(x => x.displayKey == 'Detailed');
    }

    public get formattedReportType() {
        // TODO when any other type instead of Detailed will be enabled changed it, till then hardcoded to detailed
        //const reportTypeId = this.exportReportCompanyLayoutInputs.reportTemplate?.ReportType;
        const reportType = this.reportTypes.find((item) => item.id === ReportType.Detailed);
        const codeListDeps = this.getCodeListDeps();

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.ReportTypeFormat').replace('{reportType}', reportType?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public get formattedPaperSize() {
        const paperSizeId = this.exportReportCompanyLayoutInputs.reportTemplate?.PaperSize;
        const paperSize = this.reportPaperSizes.find((item) => item.id === paperSizeId);
        const codeListDeps = this.getCodeListDeps();

        return this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.Layout.PaperSizeFormat').replace('{paperSize}', paperSize?.getTranslatedNameText(codeListDeps) ?? '');
    }

    public onReportTypeChange(reportTemplate: ReportTemplateEntity | undefined) {
        this.exportReportCompanyLayoutInputs.reportTemplate = reportTemplate;
    }

    /* ====================================== MAIN FUNCTIONS ====================================== */

    public async save() {
        if (this.submitted || !this.formValid) {
            return;
        }

        this.submitted = true;
        try {
            this.updateExportReportTrackingCounters();

            if (this.customPictures.size > 0 && this.customPicturesChanged) {
                await this.setCustomPictures();
            }

            await this.updateProperties();
            await this.generateAndSaveReport();

            this.close();
        }
        finally {
            this.submitted = false;
        }
    }

    public close() {
        this.modalInstance.close();
    }

    public async generateAndSaveReport() {
        // DEV INFO: If you need any additional data (like static images), then add a field to report options and feed the data from here.
        const pdfBlob = await this.spApiService.api.report.generate(await this.getGenerateOptions());
        const fileName = `${this.designDetails.isTemplate ? this.designDetails.templateName : this.designDetails.projectName + '_' + this.designDetails.designName}.pdf`;

        await this.browserService.downloadBlob(pdfBlob, fileName, false, false);
    }

    public async exportHtmlReport() {
        if (this.submitted || !this.formValid) {
            return;
        }

        this.submitted = true;
        try {
            // we must open a window before await
            const htmlWindow = window.open('about:blank', '_blank')!;

            if (this.customPictures.size > 0 && this.customPicturesChanged) {
                await this.setCustomPictures();
            }

            await this.updateProperties();

            const input = await this.getGenerateOptions();
            const userLogoId = input.template?.userLogoId;

            let pdfHtml = await this.spApiService.api.report.generateHtml(input);

            // set content urls for "UI html"
            pdfHtml = pdfHtml.replaceAll(/\/html-report\/static-content\/(.*?)(["'])/g, (substring, url, endTick) => `${environment.htmlReportContentUrl}static-content/${url}${endTick}`);
            pdfHtml = pdfHtml.replaceAll(/\/html-report\/custom-image\/(.*?)(["'])/g, (substring, url, endTick) => `${environment.htmlReportContentUrl}custom-image/${url}${endTick}`);
            pdfHtml = pdfHtml.replaceAll(/^(\s*)<!-- stylesheet: "(.+?)" -->(\s*)$/gm, (substring, prefix, url, postfix) => `${prefix}<link rel="stylesheet" href="${url}">${postfix}`);

            if (userLogoId != null) {
                pdfHtml = pdfHtml.replaceAll(`/html-report/template-logo/${userLogoId}`, '');
            }

            htmlWindow.document.write(pdfHtml);
            htmlWindow.document.close();

            // for report template logo we need to make a manual request with headers
            if (userLogoId != null) {
                const logo = await this.getReportTemplateLogo(userLogoId);
                if (logo != null) {
                    htmlWindow.document.querySelectorAll('.header-user-logo').forEach(element => {
                        if ('src' in element) {
                            element.src = logo;
                        }
                    });
                }
            }
        }
        finally {
            this.submitted = false;
        }
    }

    public async updateProperties() {
        const propertyChanges: PropertyIdValue[] = [];

        // Start page numbering/firstPageNumber has a max value of Number.MAX_SAFE_INTEGER which is more than C# int.MaxValue
        // we limit the number to 100 000 000
        if (this.exportReportCompanyLayoutInputs.firstPageNumber > 100000000) {
            this.exportReportCompanyLayoutInputs.firstPageNumber = 100000000;
        }

        propertyChanges.push({ propertyId: 'reportTemplateId', propertyValue: this.getReportTemplateId(), });
        propertyChanges.push({ propertyId: 'reportTypeId', propertyValue: this.exportReportCompanyLayoutInputs.reportTypeDropdown.selectedValue as number });
        propertyChanges.push({ propertyId: 'reportFirstPage', propertyValue: this.exportReportCompanyLayoutInputs.firstPageNumber });
        propertyChanges.push({ propertyId: 'reportLanguageId', propertyValue: this.exportReportCompanyLayoutInputs.languageDropdown.selectedValue as number });
        propertyChanges.push({ propertyId: 'reportPaperSizeId', propertyValue: this.exportReportCompanyLayoutInputs.paperSizeDropdown.selectedValue as number });
        propertyChanges.push({ propertyId: 'reportCompanyName', propertyValue: this.exportReportCompanyLayoutInputs.reportCompanyName });
        propertyChanges.push({ propertyId: 'reportAddress', propertyValue: this.exportReportCompanyLayoutInputs.reportAddress });
        propertyChanges.push({ propertyId: 'reportContactPerson', propertyValue: this.exportReportCompanyLayoutInputs.reportContactPerson });
        propertyChanges.push({ propertyId: 'reportPhoneNumber', propertyValue: this.exportReportCompanyLayoutInputs.reportPhoneNumber });
        propertyChanges.push({ propertyId: 'reportEmail', propertyValue: this.exportReportCompanyLayoutInputs.reportEmail });
        propertyChanges.push({
            propertyId: 'reportCustomPictures', propertyValue: [
                this.customPictures.get(1)?.imgId ?? '',
                this.customPictures.get(2)?.imgId ?? '',
                this.customPictures.get(3)?.imgId ?? ''
            ]
        });
        propertyChanges.push({ propertyId: 'reportStrengtheningApplication', propertyValue: this.exportReportProjectDetailsInputs.fasteningPoint });
        propertyChanges.push({ propertyId: 'reportNotes', propertyValue: this.exportReportProjectDetailsInputs.notes });

        await this.modalInstance.input!.propertyChange(propertyChanges);
    }

    private updateExportReportTrackingCounters() {
        const counters = this.trackingDetails.counters;

        counters.reportCreated++;

        const reportTemplateId = this.getReportTemplateId();
        if (reportTemplateId == ReportTemplateId.Custom) {
            counters.reportCreatedWithCustomTemplate++;
        }
        else if (reportTemplateId == ReportTemplateId.Default) {
            counters.reportCreatedWithDefaultTemplate++;
        }
        else {
            counters.reportCreatedWithUserTemplate++;
        }
    }

    private async getReportTemplateLogo(reportTemplateId: number): Promise<string | undefined> {
        const logo = await this.reportTemplateService.getLogo(reportTemplateId);
        if (logo != null) {
            if (logo.base64 != null) {
                return logo.base64;
            }
            else {
                return await new Promise<string>(resolve => {
                    const reader = new FileReader();
                    reader.addEventListener('load', () => {
                        resolve(reader.result as string);
                    }, false);
                    reader.readAsDataURL(logo.blob);
                });
            }
        }

        return undefined;
    }

    private async getGenerateOptions(): Promise<ApiDesignReportGenerateOptions> {
        const reportScreenshot = await this.generateReportScreenshot();

        const template: TemplateOptions = {
            excludeCompanyDetails: false
        };

        const isCustomReportTemplate = this.exportReportCompanyLayoutInputs.reportTemplate == null;
        const reportTemplate = isCustomReportTemplate
            ? this.reportTemplateService.templates.find(x => x.IsTemplate)
            : this.exportReportCompanyLayoutInputs.reportTemplate;

        // report templates are disabled if we have zero templates
        if (this.reportTemplateService.templates.length > 0) {
            if (reportTemplate == null) {
                throw new Error('report template not found');
            }

            if (this.isStandardLicense()) {
                template.footerText = undefined;
                template.headerText = undefined;
                template.userLogoId = undefined;
            }
            else {
                // custom template will still take data from default template
                template.headerText = reportTemplate.TemplateOptions
                    .find(x => x.TemplateOptionLocationType == TemplateOptionLocationType.Header && x.TemplateOptionType == TemplateOptionType.CustomText)
                    ?.Value;

                template.footerText = reportTemplate.TemplateOptions
                    .find(x => x.TemplateOptionLocationType == TemplateOptionLocationType.Footer && x.TemplateOptionType == TemplateOptionType.CustomText)
                    ?.Value;

                template.userLogoId = reportTemplate.TemplateOptions
                    .some(x => x.TemplateOptionLocationType == TemplateOptionLocationType.Header && x.TemplateOptionType == TemplateOptionType.Logo)
                    ? reportTemplate.Id
                    : undefined;

            }
            template.excludeCompanyDetails = reportTemplate.RemoveProjectHeader ?? false;
            template.fax = reportTemplate.FaxNumber;
        }

        if (reportTemplate != null && !isCustomReportTemplate) {
            template.company = reportTemplate.Company;
            template.address = reportTemplate.Address;
            template.phone = reportTemplate.PhoneNumber;
            template.specifier = reportTemplate.ContactPerson;
            template.email = reportTemplate.Email;
        }
        else {
            template.company = this.exportReportCompanyLayoutInputs.reportCompanyName;
            template.address = this.exportReportCompanyLayoutInputs.reportAddress;
            template.phone = this.exportReportCompanyLayoutInputs.reportPhoneNumber;
            template.specifier = this.exportReportCompanyLayoutInputs.reportContactPerson;
            template.email = this.exportReportCompanyLayoutInputs.reportEmail;
        }

        const suiteVersion = this.sharedEnvironmentService.data.applicationVersion;

        const infoScopeChecks = this.designDetails.calculateResult?.scopeCheckResults.failedScopeChecks.filter((x) => x.severity == ScopeCheckSeverity.Info);
        const scopeCheckIds = infoScopeChecks?.map((x) => ScopeCheckId[x.name as keyof typeof ScopeCheckId])
                                              .filter((x) => x !== undefined) ?? [];
        const scopeCheckMessages = this.getScopeCheckMessages(infoScopeChecks);

        return {
            projectDesign: this.designDetails.projectDesign,
            reportPaperSizeId: this.designDetails.properties.reportPaperSizeId!,
            localization: {
                language: this.getReportLanguage(),
                numberDecimalSeparator: this.cultureInfoService.getNumberDecimalSeparator,
                numberGroupSeparator: this.cultureInfoService.getNumberGroupSeparator,
                numberGroupSizes: this.cultureInfoService.getNumberGroupSizes,
                globalRegionShortDatePattern: this.cultureInfoService.globalRegionShortDatePattern
            },
            template,
            version: suiteVersion,
            hiltiOnlineUrl: this.designDetails.commonRegion.hiltiOnlineUrl,
            // export report is disabled for design template so we can use ! on designName
            designName: this.designDetails.designName!,
            reportScreenshot: reportScreenshot,
            // add design type specific calculation result
            calculateResult: this.getCalculateResultReport(scopeCheckMessages, scopeCheckIds)
        };
    }

    private isStandardLicense() {
        return this.userService.hasfloatingLimitReached || this.userService.hasFreeLicense || this.userService.hasOnlyBasic;
    }

    private getScopeCheckMessages(infoScopeChecks: ScopeCheckResult[] | undefined) {
        if (infoScopeChecks == undefined)
            return [];

        infoScopeChecks = this.handleReportScopeCheckMessages(infoScopeChecks);
        return infoScopeChecks.map((x) => this.translationFormatService.getScopeCheckHtml(x.message))
                                                   .filter((x) => x !== undefined) ?? [];
    }

    private handleReportScopeCheckMessages(infoScopeChecks: ScopeCheckResult[]): ScopeCheckResult[] {
        infoScopeChecks.forEach((scopeCheck) => {
            switch (scopeCheck.name) {
                case 'ScGeomStructureBeam':
                    scopeCheck.message.translationParameters.forEach(param => {
                        (param as TranslatableParameter).value = 'SP.Report.ScopeCheck.ScGeomStructureBeam';
                    });
                    break;
                    case 'ScGeomStructureColumn':
                        scopeCheck.message.translationParameters.forEach(param => {
                            (param as TranslatableParameter).value = 'SP.Report.ScopeCheck.ScGeomStructureColumn';
                        });
                        break;
                    case 'ScGeomStructureSlab':
                        scopeCheck.message.translationParameters.forEach(param => {
                            (param as TranslatableParameter).value = 'SP.Report.ScopeCheck.ScGeomStructureSlab';
                        });
                        break;
                    case 'ScGeomStructureWall':
                        scopeCheck.message.translationParameters.forEach(param => {
                            (param as TranslatableParameter).value = 'SP.Report.ScopeCheck.ScGeomStructureWall';
                        });
                        break;
                default:
                    break;
            }
        });
        return infoScopeChecks;
    }

    private strengthGetCalculateResultReport(scopeCheckMessages : string[], scopeCheckIds: ScopeCheckId[]): StrengthCalculateResultReport {
        const calculateResult = this.designDetails.calculateResult! as StrengthCalculationResult;
        // only pass what is needed to report from CalculateResult
        return {
            isDesignValid: calculateResult.isDesignValid,
            utilizationResults: calculateResult.utilizationResults,
            kernelResult: calculateResult.kernelResult,
            scopeCheckWarnings: scopeCheckMessages,
            scopeCheckWarningsIds: scopeCheckIds
        };
    }

    private punchGetCalculateResultReport(scopeCheckMessages : string[], scopeCheckIds: ScopeCheckId[]): PunchCalculateResultReport {
        const calculateResult = this.designDetails.calculateResult! as PunchCalculationResult;
        // only pass what is needed to report from CalculateResult
        return {
            isDesignValid: calculateResult.isDesignValid,
            kernelResult: calculateResult.kernelResult,
            scopeCheckWarnings: scopeCheckMessages,
            scopeCheckWarningsIds: scopeCheckIds,
            perimeters: calculateResult.punchDefinitions3D?.perimeters
        };
    }

    private getCalculateResultReport(scopeCheckMessages : string[], scopeCheckIds: ScopeCheckId[]): CalculateResultReport {
        return designTypeSwitch(this.designDetails.designTypeId,
            () => this.strengthGetCalculateResultReport(scopeCheckMessages, scopeCheckIds),
            () => this.punchGetCalculateResultReport(scopeCheckMessages, scopeCheckIds)
        );
    }

    public getReportLanguage(): string {
        return this.languages.find((language) => language.id === this.designProperties.reportLanguageId)?.culture ?? this.defaultLanguage.culture!;
    }

    private initModelImage() {
        const screenshotSettings = {
            imgHeight: 250,
            imgWidth: 250,
            zoomed: false,
            preview: true,
            isThumbnail: false,
            loadsVisibilityInfo: undefined as unknown
        } as ScreenshotSettings;

        // async image load
        (Promise.resolve(this.modalInstance.input?.createScreenshot3D(screenshotSettings) ?? ''))
            .then(modelImage => this.modelImage = modelImage)
            .catch(error => console.error(error));
    }

    private initProjectDetailsSection() {
        //TODO determine actual values
        this.exportReportProjectDetailsInputs = {
            designName: this.designDetails.projectName + ', ' + this.designDetails.designName,
            fasteningPoint: this.designDetails.properties.reportStrengtheningApplication!,
            fasteningPointTitle: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.StrengtheningApplication'),
            fasteningPointId: 'export-report-details-section-strengtheningapplication-textbox',
            notes: this.designDetails.properties.reportNotes!,
            isIncludeDetailsInReport: false,
            reportDisabled: false,
            includeDetailsInReport: {
                items: [],
                //title: 'Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeDesign', - TODO: it might come back after production with https://hilti.atlassian.net/browse/PSP-173
                selectedValues: new Set()
            }
        };

        // TODO: it might come back after production with https://hilti.atlassian.net/browse/PSP-173
        // this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push({
        //     id: 'export-report-details-section-item-number-checkbox',
        //     text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeItemNumber'),
        //     value: ReportDetails.IncludeItemNumbers,
        // });

        // TODO: it might come back after production with https://hilti.atlassian.net/browse/PSP-210
        // this.exportReportProjectDetailsInputs.includeDetailsInReport.items?.push({
        //     id: 'export-report-details-section-include-approvals-checkbox',
        //     text: this.localizationService.getString('Agito.Hilti.Profis3.ExportReport.ProjectDetails.IncludeApprovals'),
        //     value: ReportDetails.IncludeApprovals,
        // });
    }

    private initCommentsAndNotesSection() {
        this.noteLoads = '';
        this.noteInstallationData = '';
    }

    private initCustomImagesSection() {
        const customPicturesMaxUploadSize = 2 * 1024 * 1024;
        this.customPicturesUploadSize = customPicturesMaxUploadSize;
        this.customPicturesChanged = false;

        if (this.designProperties.reportCustomPictures !== null) {
            // TODO TEAM: is it ok to load images without an await?
            this.getCustomImages()
                .catch(error => console.error(error));
        }
    }

    private initLayoutSection() {

        const codeListDeps = this.getCodeListDeps();
        const selectedReportTemplate = this.getReportTemplate();
        const selectedReportType = this.reportTypes.find((reportType) => reportType.id === this.designProperties.reportTypeId)?.id;
        const selectedReportLanguage = this.languages.find((language) => language.id === this.designProperties.reportLanguageId);
        const selectedReportPaperSize = this.reportPaperSizes.find((reportPaperSize) => reportPaperSize.id === this.designProperties.reportPaperSizeId);

        this.exportReportCompanyLayoutInputs = {
            reportTemplate: selectedReportTemplate,
            reportTemplateDisabled: this.isStandardLicense(),
            isLoadCombinationDropdownVisible: false,
            displayLoadCaseDropdown: false,
            isExternalOnlineRussianUser: false,
            handrailSafetyDesign: false,
            firstPageNumber: this.designProperties.reportFirstPage ?? 1,
            reportCompanyName: this.designProperties.reportCompanyName,
            reportAddress: this.designProperties.reportAddress,
            reportContactPerson: this.designProperties.reportContactPerson,
            reportPhoneNumber: this.designProperties.reportPhoneNumber,
            reportEmail: this.designProperties.reportEmail,
            reportTypeDropdown: getLayoutDropdowns(this.reportTypes, 'export-report-layout-section-report-type-dropdown', codeListDeps, ReportType.Detailed, selectedReportType),
            languageDropdown: getLayoutDropdowns(this.languages, 'export-report-layout-section-language-dropdown', codeListDeps, this.DEFAULT_EN_LANGUAGE, selectedReportLanguage?.id),
            paperSizeDropdown: getLayoutDropdowns(this.reportPaperSizes, 'export-report-layout-section-papersize-dropdown', codeListDeps, PaperSize.A4, selectedReportPaperSize?.id),
            loadCombinationDropdown: {},
            loadCaseDropdown: {},
            loadCaseHandrailDropdown: {},
            templateDropdown: {
                id: 'export-report-layout-section-template-dropdown',
                items: [],
                selectedValue: selectedReportTemplate != undefined ? selectedReportTemplate.Id : customTemplateId,
            }
        } as IExportReportCompanyLayoutComponentInput;

        function getLayoutDropdowns(items: CodeList[], htmlClassId: string, codeListDeps: ICodeListTextDeps, defaultValue: number, selectedValue?: number): DropdownProps<number> {
            const actualValue = selectedValue ?? defaultValue;
            return {
                id: htmlClassId,
                items: items.map(item => {
                    return {
                        value: item.id,
                        text: item.getTranslatedNameText(codeListDeps) as string
                    } as DropdownItem<number>;
                }).sort((a, b) => String(a.text[0]).localeCompare(b.text[0])),
                selectedValue: actualValue
            };
        }

    }

    private getReportTemplate() {
        const defaultTemplate = this.reportTemplateService.templates.find((template) => template.IsTemplate) ?? undefined;

        if (this.designProperties.reportTemplateId == ReportTemplateId.Default) {
            return defaultTemplate;
        }

        if (this.designProperties.reportTemplateId == ReportTemplateId.Custom) {
            return undefined;
        }

        return this.reportTemplates.find((reportTemplate) => reportTemplate.Id === this.designProperties.reportTemplateId) ?? defaultTemplate;
    }

    private getReportTemplateId() {
        const reportTemplate = this.exportReportCompanyLayoutInputs.reportTemplate;
        if (reportTemplate == null) {
            return ReportTemplateId.Custom;
        }

        if (reportTemplate.IsTemplate) {
            return ReportTemplateId.Default;
        }

        return reportTemplate.Id;
    }

    private initTrimbleConnectSection() {
        // TODO: Determine if trimble connect is needed, if not delete, if yes, then add some actual values.
        this.exportTrimbleConnectInput = {
            trimbleConnectChecked: false,
            trimbleConnectLocation: '',
            trimbleConnectReportName: '',
            trimbleConnectFolderId: '',
            trimbleConnectTooltip: '',
            isOfflineOnLine: false
        } as IExportTrimbleConnectComponentInput;
    }

    /* ====================================== SUPPORT FUNCTIONS ====================================== */

    public getArrowClass(isCollapsed: boolean) {
        return isCollapsed ? 'pe-ui-sp-sprite-lines-expanded' : 'pe-ui-sp-sprite-lines';
    }

    private getCodeListDeps(): ICodeListTextDeps {
        return {
            // TODO FILIP: fix getCodeListTextDeps input interface
            localizationService: this.localizationService as unknown as LocalizationServiceBase,
            numberService: this.numberService
        } as ICodeListTextDeps;
    }



    /* ====================================== SCREENSHOT FUNCTIONS ====================================== */

    private async generateReportScreenshot() {
        const screenshotSettings = {
            imgHeight: 1000,
            imgWidth: 1000,
            zoomed: false,
            preview: false,
            isThumbnail: false,
            loadsVisibilityInfo: undefined as unknown
        } as ScreenshotSettings;

        return await this.modalInstance.input?.createScreenshot3D(screenshotSettings) ?? '';
    }

    /* ====================================== IMAGE FUNCTIONS ====================================== */
    public async getCustomImages(): Promise<void> {
        const getPromises: { position: number; customImageName: string; promise: Promise<string> }[] = [];
        const getResults: { position: number; customImageName: string; promiseResult: PromiseSettledResult<string> }[] = [];

        try {
            for (let i = 0; i < this.designProperties.reportCustomPictures.length; i++) {
                const position = i + 1;
                const customImageName = this.designProperties.reportCustomPictures[i];

                if (customImageName) {
                    getPromises.push({
                        position,
                        customImageName,
                        promise: this.spApiService.api.external.getCustomImage(customImageName, { supressErrorMessage: true })
                    });
                }
            }
        }
        finally {
            const promiseResults = await Promise.allSettled(getPromises.map(x => x.promise));
            for (let i = 0; i < promiseResults.length; i++) {
                const getPromise = getPromises[i];

                getResults.push({
                    position: getPromise.position,
                    customImageName: getPromise.customImageName,
                    promiseResult: promiseResults[i]
                });
            }
        }

        for (const getResult of getResults) {
            if (getResult.promiseResult.status == 'fulfilled') {
                this.customPictures.set(getResult.position, {
                    imgUrl: getResult.promiseResult.value,
                    imgSize: this.getImageSize(getResult.promiseResult.value),
                    imgId: getResult.customImageName,
                    updated: false,
                    position: getResult.position
                });
            }
            else {
                throw new Error(getResult.promiseResult.reason);
            }
        }
    }

    public async setCustomPictures(): Promise<void> {
        const updatedImages = Array.from(this.customPictures.values()).filter((x) => x.updated && x.imgUrl !== '');

        const uploadPromises: { position: number; promise: Promise<UploadCustomImageResponse> }[] = [];
        const uploadResults: { position: number; promiseResult: PromiseSettledResult<UploadCustomImageResponse> }[] = [];

        try {
            for (const updatedImage of updatedImages) {
                uploadPromises.push({
                    promise: this.spApiService.api.external.uploadCustomImage({
                        imageDataUri: updatedImage.imgUrl!
                    }),
                    position: updatedImage.position
                });
            }
        }
        finally {
            const promiseResults = await Promise.allSettled(uploadPromises.map(x => x.promise));
            for (let i = 0; i < promiseResults.length; i++) {
                const uploadPromise = uploadPromises[i];

                uploadResults.push({
                    position: uploadPromise.position,
                    promiseResult: promiseResults[i]
                });
            }
        }

        for (const uploadResult of uploadResults) {
            if (uploadResult.promiseResult.status == 'fulfilled') {
                const updateImage = updatedImages.find((x) => x.position == uploadResult.position);
                if (updateImage != null) {
                    updateImage.imgId = uploadResult.promiseResult.value.imageName;
                }
            }
            else {
                throw new Error(uploadResult.promiseResult.reason);
            }
        }
    }

    private getImageSize(value: string): number {
        // The size of the decoded data can be approximated with this formula.
        return (
            4 *
            Math.ceil((value.length - 'data:image/png;base64,'.length) / 3) *
            0.5624896334383812
        );
    }

    public customPictureSelected(index: number) {
        const codeIndex = index - 1;
        const customPictureInput = this.customPicturesInputs[codeIndex];
        if (customPictureInput.value != null && customPictureInput.value != '') {
            this.uploadCustomPicture((customPictureInput.files as FileList)[0], index);
            customPictureInput.value = '';
        }
    }

    public selectCustomPicture(index: number) {
        const codeIndex = index - 1;
        const customPictureInput = this.customPicturesInputs[codeIndex];
        if (customPictureInput?.value == null || customPictureInput?.value == '') {
            customPictureInput.click();
        }
    }

    public removeCustomPicture(index: number) {
        if (this.submitted) {
            return;
        }

        if (this.customPictures.has(index)) {
            this.customPicturesUploadSize += this.customPictures.get(index)?.imgSize ?? 0;

            const deletedImage = Array.from(this.customPictures.values()).find((x) => x.position === index);

            if (deletedImage !== undefined) {
                deletedImage.updated = true;
                deletedImage.imgUrl = '';
                deletedImage.imgSize = 0;
                deletedImage.imgId = '';
            }

            this.customPicturesChanged = true;
        }
    }

    private uploadCustomPicture(file: File, index: number) {
        if (file.type != 'image/png' && file.type != 'image/jpeg') {
            this.modalService.openAlertWarning(
                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Title'),
                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.WrongFileTypeAlert.Message')
            );
            return;
        }
        if (file.size > this.customPicturesUploadSize) {
            if (this.customPictures.size < 1) {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FileTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FileTooLargeAlert.Message')
                );
                return;
            }
            else {
                this.modalService.openAlertWarning(
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FilesTooLargeAlert.Title'),
                    this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.FilesTooLargeAlert.Message')
                );
                return;
            }
        }

        const reader = new FileReader();
        reader.addEventListener('load', () => {
            const img: HTMLImageElement = new Image();
            img.src = reader.result as string;
            this.ngZone.run(() => {
                img.addEventListener('load', () => {
                    this.ngZone.run(() => {
                        if (img.naturalWidth > 1200 || img.naturalHeight > 800) {
                            this.modalService.openAlertWarning(
                                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Title'),
                                this.localizationService.getString('Agito.Hilti.Profis3.ReportTemplates.ImageTooLargeAlert.Message')
                            );
                            return;
                        }
                        this.customPicturesUploadSize -= file.size;

                        this.customPictures.set(index, {
                            imgUrl: img.src,
                            imgSize: file.size,
                            imgId: '',
                            updated: true,
                            position: index,
                        });

                        this.customPicturesChanged = true;
                    });
                }, false);
            });
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }
}
