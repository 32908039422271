import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3, Vector2, Vector3 } from '@babylonjs/core/Maths/math';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { CreatePolygon } from '@babylonjs/core/Meshes/Builders/polygonBuilder';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { VertexData } from '@babylonjs/core/Meshes/mesh.vertexData';
import { FloatArray } from '@babylonjs/core/types';
import earcut from 'earcut';
import isEqual from 'lodash-es/isEqual';
import { PunchComponent, PunchComponentConstructor } from '../../punch-component';
import { PunchBaseMaterialModel, PunchPostInstalledElementModel, VisibilityModel } from '../../punch-gl-model';
import { baseMaterialAlphaIndex, getPunchSpanOffset, getPunchWasherDiameter, getRecessHoleZPosition, getWasherZPosition, translateKernelPointTo3dPoint } from './punch-helper';

interface PunchBaseMaterialModelUpdateData {
    visibilityInfo: VisibilityModel;
    baseMaterial: PunchBaseMaterialModel;
    postInstalledElement: PunchPostInstalledElementModel;
}

interface OpeningInfo {
    length: number;
    width: number;
    originX: number;
    originY: number;
}

export const baseMaterialColor = Color3.FromHexString('#cbcabf');
export const baseMaterialDarkColor = Color3.FromHexString('#a9a89a');
export const numberOfSides = 16;

export class PunchBaseMaterialElement extends PunchComponent {
    private baseMaterialMaterial!: StandardMaterial;
    private baseMaterialMaterialDark!: StandardMaterial;
    private materialMeshTop: Mesh | null = null;
    private materialMeshBottom: Mesh | null = null;

    private baseMaterialModelUpdateData!: PunchBaseMaterialModelUpdateData;

    private recessCylinders: Mesh[];
    private recessHoleBottoms: Mesh[];

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.baseMaterialModelUpdateData = {} as PunchBaseMaterialModelUpdateData;
        this.recessCylinders = new Array<Mesh>();
        this.recessHoleBottoms = new Array<Mesh>();

        this.initMaterial();
    }

    public update(): void {
        const modelUpdateData = {
            visibilityInfo: this.getVisibilityInfos(),
            baseMaterial: this.model.baseMaterial,
            postInstalledElement: this.model.punchPostInstalledElement
        } as PunchBaseMaterialModelUpdateData;

        if (isEqual(this.baseMaterialModelUpdateData, modelUpdateData)) {
            return;
        }
        this.baseMaterialModelUpdateData = structuredClone(modelUpdateData);

        this.ensureBaseMaterialSides();
        this.ensureBaseMaterialTop();
        this.ensureBaseMaterialBottom();
    }

    private getVisibilityInfos(): VisibilityModel {
        return {
            TransparentConcrete: this.model.visibilityModel.TransparentConcrete,
            ConcreteDimensionsVisible: this.model.visibilityModel.ConcreteDimensionsVisible,
            CriticalPerimeter: this.model.visibilityModel.CriticalPerimeter,
        };
    }

    private initMaterial(): void {
        this.baseMaterialMaterial = this.createMaterial('BaseMaterialMaterial', baseMaterialColor);
        this.baseMaterialMaterialDark = this.createMaterial('BaseMaterialMaterialDark', baseMaterialDarkColor);
    }

    private ensureBaseMaterialSides() {
        const mesh = this.cache.meshCache.create('BaseMaterialSidesPunch', () => this.createSidesMesh());
        mesh.setEnabled(true);
        this.setBaseMaterialSidesProperties(mesh);
    }

    private setBaseMaterialSidesProperties(mesh: Mesh) {
        this.baseMaterialMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;
        mesh.material = this.baseMaterialMaterial;

        mesh.alphaIndex = baseMaterialAlphaIndex;

        mesh.scaling.x = this.model.baseMaterial.spanNegX + this.model.baseMaterial.spanPosX;
        mesh.scaling.y = this.model.baseMaterial.thickness;
        mesh.scaling.z = this.model.baseMaterial.spanNegY + this.model.baseMaterial.spanPosY;

        const offsetX = (-this.model.baseMaterial.spanNegX + this.model.baseMaterial.spanPosX) / 2;
        const offsetY = (-this.model.baseMaterial.spanNegY + this.model.baseMaterial.spanPosY) / 2;

        mesh.position.x = offsetX;
        mesh.position.z = offsetY;

        mesh.parent = this.cache.meshCache.getCompressionMemberTransformNode(
            this.model.baseMaterial.compressionMemberId
        );
    }

    private createMaterial(name: string, color: Color3): StandardMaterial {
        const material = this.cache.materialCache.create(name, () => new StandardMaterial(name, this.scene));
        material.diffuseColor = color;
        return material;
    }

    private ensureBaseMaterialTop(): void {
        this.disposeOldTopMesh();

        const shape = this.createBaseShapeForTop();
        const holes = this.createOpeningShapes();
        const thickness = this.model.baseMaterial.thickness;

        this.materialMeshTop = CreatePolygon(
            'MaterialTop',
            { shape, holes },
            this.scene,
            earcut
        );

        if (this.materialMeshTop) {
            this.configureMesh(this.materialMeshTop, thickness);
            this.materialMeshTop.parent = this.cache.meshCache.getCompressionMemberTransformNode(
                this.model.baseMaterial.compressionMemberId
            );
        }
    }

    private ensureBaseMaterialBottom(): void {
        this.disposeOldBottomMesh();

        const shape = this.createBaseShapeForBottom();

        const holesOpenings = this.createOpeningShapes(true);
        const holesRecess = this.createRecessShapes();
        const holes = holesOpenings.concat(holesRecess);

        this.materialMeshBottom = CreatePolygon(
            'MaterialBottom',
            { shape, holes },
            this.scene,
            earcut
        );

        if (this.materialMeshBottom) {
            this.configureMesh(this.materialMeshBottom, -this.model.baseMaterial.thickness);
            this.materialMeshBottom.rotation.x = Math.PI;
            this.materialMeshBottom.parent = this.cache.meshCache.getCompressionMemberTransformNode(
                this.model.baseMaterial.compressionMemberId
            );
        }
    }

    private createBaseShapeForTop(): Vector3[] {
        return [
            new Vector3(-this.model.baseMaterial.spanNegX, 0, this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, -this.model.baseMaterial.spanNegY),
            new Vector3(-this.model.baseMaterial.spanNegX, 0, -this.model.baseMaterial.spanNegY)
        ];
    }

    private createBaseShapeForBottom(): Vector3[] {
        return [
            new Vector3(-this.model.baseMaterial.spanNegX, 0, -this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, -this.model.baseMaterial.spanPosY),
            new Vector3(this.model.baseMaterial.spanPosX, 0, this.model.baseMaterial.spanNegY),
            new Vector3(-this.model.baseMaterial.spanNegX, 0, this.model.baseMaterial.spanNegY)
        ];
    }

    private createOpeningShapes(isBottom = false): Vector3[][] {
        const openings: OpeningInfo[] = [
            { length: this.model.baseMaterial.punchOpening1Length, width: this.model.baseMaterial.punchOpening1Width, originX: this.model.baseMaterial.punchOpening1OriginX, originY: this.model.baseMaterial.punchOpening1OriginY },
            { length: this.model.baseMaterial.punchOpening2Length, width: this.model.baseMaterial.punchOpening2Width, originX: this.model.baseMaterial.punchOpening2OriginX, originY: this.model.baseMaterial.punchOpening2OriginY },
            { length: this.model.baseMaterial.punchOpening3Length, width: this.model.baseMaterial.punchOpening3Width, originX: this.model.baseMaterial.punchOpening3OriginX, originY: this.model.baseMaterial.punchOpening3OriginY },
        ];

        return openings.map(opening => this.getRectangleShape(opening, isBottom));
    }

    private createRecessShapes(): Vector3[][] {
        this.recessCylinders.forEach(x => x?.dispose());
        this.recessHoleBottoms.forEach(x => x?.dispose());

        if (this.model.punchPostInstalledElement.depthOfRecess === 0) {
            return [];
        }

        const anchorDiameter = getPunchWasherDiameter(this.model);

        // get points from all perimeters as single array
        const recessPoints = this.model.punchPostInstalledElement.perimeters.flatMap((p) => p.points);

        const recessHoles: Vector3[][] = [];
        const punchOffset = getPunchSpanOffset(this.model);

        for(let pNum = 0; pNum < recessPoints.length; pNum++) {
            const recessPoint = recessPoints[pNum];

            recessHoles.push(this.getCircleShape(new Vector3(recessPoint.x - punchOffset.x, 0, -recessPoint.y + punchOffset.y), anchorDiameter));
            this.createRecessCyliner(recessPoint, anchorDiameter);
        }
        return recessHoles;
    }
    private createRecessCyliner(pos: Vector2, anchorDiameter: number): void {
        const cylinder = CreateCylinder(`PunchBaseMaterial_recess_cylinder_${pos.x}-${pos.y}`, {
                    tessellation: 24, height: this.model.punchPostInstalledElement.depthOfRecess, sideOrientation: Mesh.BACKSIDE, cap: Mesh.NO_CAP
                }, this.scene);
        cylinder.material = this.baseMaterialMaterial;
        cylinder.position = translateKernelPointTo3dPoint(this.model, pos, getRecessHoleZPosition(this.model));
        cylinder.scaling = new Vector3(anchorDiameter, 1, anchorDiameter);
        cylinder.rotation.x = Math.PI / 2;
        cylinder.setEnabled(true);
        cylinder.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        this.recessCylinders.push(cylinder);

        const holeShapeBottom = this.getCircleShape(Vector3.Zero(), 1);
        const holeBottomMesh = CreatePolygon(`PunchBaseMaterial_recess_hole_${pos.x}-${pos.y}`, { shape: holeShapeBottom, sideOrientation: Mesh.BACKSIDE }, this.scene, earcut);
        holeBottomMesh.material = this.baseMaterialMaterialDark;
        holeBottomMesh.position = translateKernelPointTo3dPoint(this.model, pos, getWasherZPosition(this.model));
        holeBottomMesh.scaling = new Vector3(anchorDiameter, 1, anchorDiameter);
        holeBottomMesh.rotation.x = -Math.PI / 2;
        holeBottomMesh.setEnabled(true);
        holeBottomMesh.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        this.recessHoleBottoms.push(holeBottomMesh);
    }

    private getRectangleShape({ length, width, originX, originY }: OpeningInfo, isBottom: boolean): Vector3[] {
        const centerZ = isBottom ? -originY : originY;
        const halfLength = length / 2;
        const halfWidth = width / 2;

        return [
            new Vector3(originX - halfLength, 0, centerZ - halfWidth),
            new Vector3(originX + halfLength, 0, centerZ - halfWidth),
            new Vector3(originX + halfLength, 0, centerZ + halfWidth),
            new Vector3(originX - halfLength, 0, centerZ + halfWidth)
        ];
    }

    private configureMesh(mesh: Mesh, thickness: number): void {
        mesh.setEnabled(true);
        mesh.material = this.baseMaterialMaterial;
        this.baseMaterialMaterial.alpha = this.model.visibilityModel.TransparentConcrete ? 0.5 : 1;
        mesh.alphaIndex = baseMaterialAlphaIndex;
        mesh.position = new Vector3(0, thickness / 2, 0);
    }

    private createSidesMesh() {
        const positions = [
            -0.5, -0.5, -0.5,
            0.5, -0.5, -0.5,
            0.5, 0.5, -0.5,

            -0.5, -0.5, -0.5,
            0.5, 0.5, -0.5,
            -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5,
            -0.5, -0.5, -0.5,
            -0.5, 0.5, -0.5,

            -0.5, -0.5, 0.5,
            -0.5, 0.5, -0.5,
            -0.5, 0.5, 0.5,

            -0.5, -0.5, 0.5,
            0.5, 0.5, 0.5,
            0.5, -0.5, 0.5,

            -0.5, -0.5, 0.5,
            -0.5, 0.5, 0.5,
            0.5, 0.5, 0.5,

            0.5, -0.5, 0.5,
            0.5, 0.5, -0.5,
            0.5, -0.5, -0.5,

            0.5, -0.5, 0.5,
            0.5, 0.5, 0.5,
            0.5, 0.5, -0.5,
        ];
        const indices = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23];

        return this.createMesh(positions, indices, 'BaseMaterialSidesPunch');
    }

    private createMesh(positions: number[], indices: number[], name: string) {
        const mesh = new Mesh(name, this.scene);
        const normals: FloatArray = [];
        const vertexData = new VertexData();

        VertexData.ComputeNormals(positions, indices, normals);

        vertexData.positions = positions;
        vertexData.indices = indices;
        vertexData.normals = normals;
        vertexData.applyToMesh(mesh);

        return mesh;
    }

    private getCircleShape(position: Vector3, size: number): Vector3[] {
        const circlePoints: Vector3[] = [];
        this.cache.commonCache.getCirclePoints(numberOfSides, 1).forEach((point) => {
            circlePoints.push(new Vector3(position.x + point.x * size, position.y, position.z + point.y * size));
        });
        return circlePoints;
    }


    private disposeOldBottomMesh(): void {
        if (this.materialMeshBottom) {
            this.materialMeshBottom.dispose();
            this.materialMeshBottom = null;
        }
    }
    private disposeOldTopMesh(): void {
        if (this.materialMeshTop) {
            this.materialMeshTop.dispose();
            this.materialMeshTop = null;
        }
    }
    public override dispose(): void {
        this.disposeOldBottomMesh();
        this.disposeOldTopMesh();
        super.dispose();
    }
}
