<div class="utilization" >

    <div class="utilization-info-content" [id]="controlId">
        <div class="info-content">

            <!-- Numeric utilization with header property title -->
            <div class="number-container" *ngIf="showNumberContainer">
                <div class="details-line">
                    <p class="text" [innerHTML]="title" [id]="getComponentId('header')"></p>
                    <p [ngClass]="getNumberContainerStyle()" *ngIf="hasValue" [id]="getComponentId('percentage')">{{ getValue() }}</p>
                </div>
            </div>

            <!-- Percentage bar -->
            <div class="text-progress-container">
                <div [ngClass]="getPercentageBarStyle()" *ngIf="showPercentBar()">
                    <div class="progress-percent" [ngStyle]="{ 'width':  getValueNumber() + '%' }"></div>
                </div>
            </div>

        </div>
    </div>

</div>
