import isEqual from 'lodash-es/isEqual';

import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { createCSGFromVertexData } from '@profis-engineering/gl-model/csg';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper';
import { CreateDashedLines, CreateLines } from '@babylonjs/core/Meshes/Builders/linesBuilder';
import { PunchComponent, PunchComponentConstructor } from '../../punch-component';
import { numberOfSides } from '../strength/post-installed-element-helper';
import { getWasherZPosition, translateKernelPointTo3dPoint } from './punch-helper';
import { LinesMesh } from '@babylonjs/core/Meshes/linesMesh';

const elementColor = Color3.FromHexString('#ee0011');
const washerColor = Color3.FromHexString('#ffdc40');

interface PuchPostInstalledElementUpdateData {
    punchPostInstalledElement: PunchPostInstalledElement;
}

export const anchorProtuberance = 20;
const elementTesseletaion = 16;
const perimeterVerticalOffset = 5;
export const washerFillHolesHeight = 5;
const fillHolesHolePoints = 8;
const fillHolesHoleScale = 1 / 7.5;
const fillHolesHolePosition = new Vector2(-CommonCache.cylinderSizeHalf * (2 / 3), 0);

const showPostInstalledElement = true;

export class PunchPostInstalledElement extends PunchComponent {
    private elementMesh: Mesh | undefined;
    private washerMesh: Mesh | undefined;
    private elementMeshes: InstancedMesh[];
    private washerMeshes: InstancedMesh[];
    private controlPerimetersTop: LinesMesh[];
    private outerPerimetersTop: LinesMesh[];
    private controlPerimetersBottom: LinesMesh[];
    private outerPerimetersBottom: LinesMesh[];
    private elementMaterial!: StandardMaterial;
    private washerMaterial!: StandardMaterial;
    private postInstalledElementModelUpdateData: PuchPostInstalledElementUpdateData;

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.elementMeshes = new Array<InstancedMesh>();
        this.washerMeshes = new Array<InstancedMesh>();
        this.controlPerimetersTop = new Array<LinesMesh>();
        this.outerPerimetersTop = new Array<LinesMesh>();
        this.controlPerimetersBottom = new Array<LinesMesh>();
        this.outerPerimetersBottom = new Array<LinesMesh>();
        this.postInstalledElementModelUpdateData = {} as PuchPostInstalledElementUpdateData;
        this.initMaterial();
    }

    private initMaterial() {
        this.elementMaterial = new StandardMaterial('PunchPostInstalledElementMaterial', this.scene);
        this.elementMaterial.diffuseColor = elementColor;

        this.washerMaterial = new StandardMaterial('PunchPostInstalledWasherMaterial', this.scene);
        this.washerMaterial.diffuseColor = washerColor;
    }

    public update(): void {
        // Only update if the relevant values have actually changed
        const modelUpdateData = {
            baseMaterial: this.model.baseMaterial,
            postInstalledElement: this.model.punchPostInstalledElement,
            visibilityModel: this.model.visibilityModel
        };
        if (isEqual(this.postInstalledElementModelUpdateData, modelUpdateData)) {
            return;
        }
        this.postInstalledElementModelUpdateData = structuredClone(modelUpdateData);

        this.ensureMesh();
    }

    private ensureMesh() {
        this.disposeMeshes(this.elementMeshes);
        this.disposeMeshes(this.washerMeshes);

        this.createElementMesh();
        this.createWasherMesh();

        this.ensureControlPerimeterMesh();
        this.ensureOuterPerimeterMesh();

        // get points from all perimeters as single array
        const pointsFlat = this.model.punchPostInstalledElement.perimeters.flatMap((p) => p.points);

        for(let pNum = 0; pNum < pointsFlat.length; pNum++) {
            this.elementMeshes.push(this.createElementMeshInstance(pointsFlat[pNum]));
            this.washerMeshes.push(this.createWasherMeshInstance(pointsFlat[pNum]));
        }
    }

    private createElementMesh() {
        this.elementMesh = this.cache.meshCache.create('PunchPostInstalledElement', () => CreateCylinder('PunchPostInstalledElement', { tessellation: elementTesseletaion }, this.scene));
        this.elementMesh.material = this.elementMaterial;
        this.elementMesh.setEnabled(false);
    }

    private createWasherMesh() {
        this.washerMesh = this.cache.meshCache.create('PunchPostInstalledWasher', () => {
            const fillHolesVertexData = cloneVertexData(this.cache.vertexDataCache.cylinder(numberOfSides).vertexData);
            fillHolesVertexData.transform(Matrix.Scaling(1, washerFillHolesHeight / CommonCache.cylinderSize, 1));

            const fillHolesCSG = createCSGFromVertexData(fillHolesVertexData);
            const fillHolesSubtractVertexData = cloneVertexData(this.cache.vertexDataCache.cylinder(fillHolesHolePoints).vertexData);

            let fillHolesSubtractMatrix = Matrix.Scaling(fillHolesHoleScale, 2, fillHolesHoleScale);
            fillHolesSubtractMatrix = fillHolesSubtractMatrix.multiply(Matrix.Translation(fillHolesHolePosition.x, 0, fillHolesHolePosition.y));
            fillHolesSubtractVertexData.transform(fillHolesSubtractMatrix);

            const fillHolesSubtractCSG = createCSGFromVertexData(fillHolesSubtractVertexData);

            fillHolesCSG.subtractInPlace(fillHolesSubtractCSG);

            return fillHolesCSG.toMesh('PunchPostInstalledWasher', this.washerMaterial, this.scene, false);
        });
        this.washerMesh.setEnabled(false);
    }

    private createElementMeshInstance(point: Vector2) {
        const name =`PunchPostInstalledElement_${point.x}_${point.y}`;
        const diameter = this.model.punchPostInstalledElement.anchorDiameter;

        const mesh = this.elementMesh?.createInstance(name);
        mesh!.scaling = new Vector3(diameter, this.anchorLength, diameter);
        mesh!.position = translateKernelPointTo3dPoint(this.model, point, -this.anchorZPosition);
        mesh!.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        mesh!.rotation.x = Math.PI / 2;
        mesh!.setEnabled(true);

        return mesh!;
    }

    private createWasherMeshInstance(point: Vector2) {
        const name =`PunchPostInstalledWasher_${point.x}_${point.y}`;
        const radius = (this.model.punchPostInstalledElement.anchorDiameter * 3) / 2;

        const mesh = this.washerMesh?.createInstance(name);
        mesh!.scaling = new Vector3(radius / CommonCache.cylinderSizeHalf, 1, radius / CommonCache.cylinderSizeHalf);
        mesh!.position = translateKernelPointTo3dPoint(this.model, point, getWasherZPosition(this.model));
        mesh!.rotation.x = Math.PI / 2;
        mesh!.setEnabled(showPostInstalledElement);
        mesh!.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);

        return mesh!;
    }

    private get ShowPerimeters() {
        return this.model.visibilityModel.CriticalPerimeter && this.model.punchPostInstalledElement.defineStrengtheningElement;
    }

    private ensureControlPerimeterMesh() {
        this.controlPerimetersTop.forEach(element => element.dispose());
        this.controlPerimetersBottom.forEach(element => element.dispose());

        if (this.model.punchPostInstalledElement.controlPerimeters != null) {
            for (let i = 0; i < this.model.punchPostInstalledElement.controlPerimeters.length; i++) {

                const points : Vector3[] = [];
                this.model.punchPostInstalledElement.controlPerimeters[i].forEach(point => {
                    const tmpPoint = translateKernelPointTo3dPoint(this.model, point, 0);
                    points.push(new Vector3(tmpPoint.x, 0, tmpPoint.y));
                    });

                const options = {
                    points: points,
                    updatable: true
                };

                this.controlPerimetersTop[i] = CreateLines('ControlPerimeterTop' + i, options, this.scene);
                this.controlPerimetersTop[i].color = Color3.Red();
                this.controlPerimetersTop[i].setEnabled(this.ShowPerimeters);
                this.controlPerimetersTop[i].position.y = this.model.baseMaterial.thickness / 2 + perimeterVerticalOffset;

                this.controlPerimetersBottom[i] = CreateLines('ControlPerimeterBottom' + i, options, this.scene);
                this.controlPerimetersBottom[i].color = Color3.Red();
                this.controlPerimetersBottom[i].setEnabled(this.ShowPerimeters);
                this.controlPerimetersBottom[i].position.y = - this.model.baseMaterial.thickness / 2 - perimeterVerticalOffset;
            }
        }
    }

    private ensureOuterPerimeterMesh() {
        this.outerPerimetersTop.forEach(element => element.dispose());
        this.outerPerimetersBottom.forEach(element => element.dispose());

        if (this.model.punchPostInstalledElement.outerPerimeters != null) {
            for (let i = 0; i < this.model.punchPostInstalledElement.outerPerimeters.length; i++) {

                const points : Vector3[] = [];
                this.model.punchPostInstalledElement.outerPerimeters[i].forEach(point => {
                    const tmpPoint = translateKernelPointTo3dPoint(this.model, point, 0);
                    points.push(new Vector3(tmpPoint.x, 0, tmpPoint.y));
                    });
                const options = {
                    points: points,
                    updatable: true,
                    gapSize: 24,
                    dashSize: 24
                };

                this.outerPerimetersTop[i] = CreateDashedLines('OuterPerimeterTop' + i, options, this.scene);
                this.outerPerimetersTop[i].setEnabled(this.ShowPerimeters);
                this.outerPerimetersTop[i].color = Color3.Black();
                this.outerPerimetersTop[i].position.y = this.model.baseMaterial.thickness / 2 + perimeterVerticalOffset;

                this.outerPerimetersBottom[i] = CreateDashedLines('OuterPerimeterBottom' + i, options, this.scene);
                this.outerPerimetersBottom[i].setEnabled(this.ShowPerimeters);
                this.outerPerimetersBottom[i].color = Color3.Black();
                this.outerPerimetersBottom[i].position.y = - this.model.baseMaterial.thickness / 2 - perimeterVerticalOffset;
            }
        }
    }

    private get anchorZPosition(): number {
        const trueZero = this.model.baseMaterial.thickness / 2;
        return this.anchorLength - trueZero - anchorProtuberance + this.model.punchPostInstalledElement.depthOfRecess;
    }
    private get anchorLength(): number {
        return this.model.punchPostInstalledElement.drillLength / 2 + anchorProtuberance / 2;
    }
    private disposeMeshes(meshes: InstancedMesh[]) {
        for (const mesh of meshes) {
            mesh.setEnabled(false);
            mesh.parent = this.cache.meshCache.getBaseMemberTransformNode(this.model.baseMaterial.baseMemberId);
        }
        meshes = [];
    }
}
