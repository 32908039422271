import isEqual from 'lodash-es/isEqual';

import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { Matrix, Vector2, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { CreateCylinder } from '@babylonjs/core/Meshes/Builders/cylinderBuilder';
import { InstancedMesh } from '@babylonjs/core/Meshes/instancedMesh';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { CommonCache } from '@profis-engineering/gl-model/cache/common-cache';
import { createCSGFromVertexData } from '@profis-engineering/gl-model/csg.js';
import { cloneVertexData } from '@profis-engineering/gl-model/vertex-data-helper.js';

import { ZoneNumber } from '../../../services/data.service';
import { StrengthComponent, StrengthComponentConstructor } from '../../strength-component';
import {
    BaseMaterialModel, OpeningModel, PostInstalledElementModel, ZonesModel
} from '../../strength-gl-model';
import { calculateHoleDiameter } from './opening';
import { CalculateElementPositions, numberOfSides } from './post-installed-element-helper';
import { GetStrengtheningElementDefinition } from './zones-helper';

const elementColor = Color3.FromHexString('#ee0011');
const washerColor = Color3.FromHexString('#ffdc40');

interface PostInstalledElementModelUpdateData {
    baseMaterial: BaseMaterialModel;
    zones: ZonesModel;
    opening: OpeningModel;
    postInstalledElement: PostInstalledElementModel;
}

// TODO JANJ: Anchor properties (Will be done in PSP-37)
export const anchorProtuberance = 10;
const fillHolesHolePoints = 8;
const fillHolesHoleScale = 1 / 7.5;
const fillHolesHolePosition = new Vector2(-CommonCache.cylinderSizeHalf * (2 / 3), 0);
const fillHolesHeight = 5;
const elementTesseletaion = 16;

export class PostInstalledElement extends StrengthComponent {
    private elementMesh: Mesh | undefined;
    private washerMesh: Mesh | undefined;
    private elementMeshes: InstancedMesh[];
    private washerMeshes: InstancedMesh[];
    private elementMaterial!: StandardMaterial;
    private washerMaterial!: StandardMaterial;

    private postInstalledElementModelUpdateData: PostInstalledElementModelUpdateData;

    constructor(ctor: StrengthComponentConstructor) {
        super(ctor);
        this.elementMeshes = new Array<InstancedMesh>();
        this.washerMeshes = new Array<InstancedMesh>();
        this.postInstalledElementModelUpdateData = {} as PostInstalledElementModelUpdateData;
        this.initMaterial();
    }

    private initMaterial() {
        this.elementMaterial = new StandardMaterial('PostInstalledElementMaterial', this.scene);
        this.elementMaterial.diffuseColor = elementColor;

        this.washerMaterial = new StandardMaterial('WasherMaterial', this.scene);
        this.washerMaterial.diffuseColor = washerColor;
    }

    public update(): void {
        // Only update if the relevant values have actually changed
        const modelUpdateData = {
            baseMaterial: this.model.baseMaterial,
            zones: this.model.zones,
            opening: this.model.opening,
            postInstalledElement: this.model.postInstalledElement
        };
        if (isEqual(this.postInstalledElementModelUpdateData, modelUpdateData)) {
            return;
        }
        this.postInstalledElementModelUpdateData = structuredClone(modelUpdateData);

        // Ensure the mesh is created
        this.ensureMesh();
    }

    private ensureMesh() {
        for (const elementMesh of this.elementMeshes) {
            elementMesh.setEnabled(false);

            // rotation mesh
            elementMesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
        }
        this.elementMeshes = [];

        for (const washerMesh of this.washerMeshes) {
            washerMesh.setEnabled(false);

            // rotation mesh
            washerMesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);
        }
        this.washerMeshes = [];

        this.createElementMesh();
        this.createWasherMesh();

        for (let zoneNumber = 1; zoneNumber <= this.model.zones.numberOfZones; zoneNumber++) {
            if (GetStrengtheningElementDefinition(this.model, zoneNumber as ZoneNumber)) {
                const positions = CalculateElementPositions(this.model, zoneNumber as ZoneNumber, anchorProtuberance);

                for (let i = 0; i < positions.length; i++) {
                    this.elementMeshes.push(this.createElementMeshInstance(positions[i], i, zoneNumber as ZoneNumber));
                    if (!this.model.postInstalledElement.withoutNutAndWasher) {
                        this.washerMeshes.push(this.createWasherMeshInstance(positions[i], i, zoneNumber as ZoneNumber));
                    }
                }
            }
        }
    }

    private createElementMesh() {
        this.elementMesh = this.cache.meshCache.create('PostInstalledElement', () => CreateCylinder('PostInstalledElement', { tessellation: elementTesseletaion }, this.scene));
        this.elementMesh.material = this.elementMaterial;
        this.elementMesh.setEnabled(false);
    }

    private createWasherMesh() {
        this.washerMesh = this.cache.meshCache.create('Washer', () => {
            const fillHolesVertexData = cloneVertexData(this.cache.vertexDataCache.cylinder(numberOfSides).vertexData);
            fillHolesVertexData.transform(Matrix.Scaling(1, fillHolesHeight / CommonCache.cylinderSize, 1));

            const fillHolesCSG = createCSGFromVertexData(fillHolesVertexData);
            const fillHolesSubtractVertexData = cloneVertexData(this.cache.vertexDataCache.cylinder(fillHolesHolePoints).vertexData);

            let fillHolesSubtractMatrix = Matrix.Scaling(fillHolesHoleScale, 2, fillHolesHoleScale);
            fillHolesSubtractMatrix = fillHolesSubtractMatrix.multiply(Matrix.Translation(fillHolesHolePosition.x, 0, fillHolesHolePosition.y));
            fillHolesSubtractVertexData.transform(fillHolesSubtractMatrix);

            const fillHolesSubtractCSG = createCSGFromVertexData(fillHolesSubtractVertexData);

            fillHolesCSG.subtractInPlace(fillHolesSubtractCSG);

            return fillHolesCSG.toMesh('Washer', this.washerMaterial, this.scene, false);
        });
        this.washerMesh.setEnabled(false);
    }

    private createElementMeshInstance(position: Vector3, anchorNumber: number, zoneNumber: ZoneNumber): InstancedMesh {
        const name = 'PostInstalledElement' + anchorNumber + 'Zone' + zoneNumber;
        const mesh = this.elementMesh!.createInstance(name);

        mesh.scaling.x = this.model.postInstalledElement.anchorDiameter;
        mesh.scaling.y = this.model.postInstalledElement.drillLength / 2 + anchorProtuberance;
        mesh.scaling.z = this.model.postInstalledElement.anchorDiameter;
        mesh.position = position;

        mesh.setEnabled(true);

        // rotation mesh
        mesh.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);

        return mesh;
    }

    private createWasherMeshInstance(position: Vector3, washerNumber: number, zoneNumber: ZoneNumber) {
        const name = 'Washer' + washerNumber + 'Zone' + zoneNumber;

        const mesh = this.washerMesh?.createInstance(name);

        // scale mesh
        const diameter = calculateHoleDiameter(this.model.postInstalledElement.anchorDiameter);
        const radius = diameter / 2;
        mesh!.scaling = new Vector3(radius / CommonCache.cylinderSizeHalf, 1, radius / CommonCache.cylinderSizeHalf);

        // position mesh
        mesh!.position = new Vector3(position.x, - fillHolesHeight / 2 - this.model.baseMaterial.height / 2 + this.model.postInstalledElement.depthOfRecess, position.z);

        mesh!.setEnabled(true);

        // rotation mesh
        mesh!.parent = this.cache.meshCache.getConcreteMemberTransformNode(this.model.baseMaterial.concreteMemberId);

        return mesh!;
    }
}
