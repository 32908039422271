import { Component, Input, OnInit } from '@angular/core';
import { GenericUtilizationItem, PropertyType } from '../../../services/design.service';
import { UnitService } from '../../../services/unit.service';
import { LocalizationService } from '../../../services/localization.service';
import { UnitGroup } from '@profis-engineering/pe-ui-common/helpers/unit-helper';

@Component({
    selector: 'app-utilization-item',
    templateUrl: './utilization-item.component.html',
    styleUrls: ['./utilization-item.component.scss']
})

export class UtilizationItemComponent implements OnInit {
    @Input()
    public controlId = '';

    @Input()
    public showNumberContainer = true;

    @Input()
    public title? = '';

    @Input()
    public utilizationVariable? = '';

    @Input()
    public header = '';

    @Input()
    public utilizationItem?: GenericUtilizationItem;

    private value: string | number | boolean = '';
    private propertyType: PropertyType = PropertyType.Numeric;
    private unitGroup: UnitGroup = UnitGroup.None;

    constructor(
        private unit: UnitService,
        private localization: LocalizationService,
    ) { }

    public ngOnInit(): void {
        this.value = this.utilizationItem!.propertyValue;
        this.propertyType = this.utilizationItem!.propertyType;
        this.unitGroup = this.utilizationItem!.unitGroup ?? UnitGroup.None;
    }

    public get hasValue(): boolean {
        return this.value != null;
    }

    public getComponentId(type: string) {
        return `${this.controlId}-${type}`;
    }

    public getValueNumber(): number | undefined {
        if (this.value == null) {
            return this.value;
        }

        return this.value as number;
    }

    private roundValue(value: number) {
        return Math.ceil(value);
    }

    public getValuePercentage(): string | undefined {
        if (this.value == null || !this.showPercentBar) {
            return this.value as string;
        }

        let returnVal = this.roundValue(this.value as number).toString();
        if (!this.value.toString().endsWith('%')) {
            returnVal += ' %';
        }
        return returnVal;
    }

    private translate(key: string) {
        if (key == undefined) {
            return key;
        }

        return this.localization.getString(key);
    }

    public getValueFormattedBoolean(): string {
        const noStr = '[' + this.translate('SP.General.No').toUpperCase() + ']';
        const yesStr = '[' + this.translate('SP.General.Yes').toUpperCase() + ']';
        if (this.value == null) {
            return noStr;
        }

        return this.value ? yesStr : noStr;
    }

    public getValue(): string {
        let ret = undefined;
        if (this.propertyType === PropertyType.Boolean) {
            ret = this.getValueFormattedBoolean();
        }
        else if (this.propertyType === PropertyType.Numeric) {
            if (this.unitGroup == UnitGroup.None) {
                ret = this.value as string;
            }
            else if (this.unitGroup === UnitGroup.Percentage) {
                ret = this.getValuePercentage() ?? undefined;
            }
            else {
                const unitStr = this.unit.convertInternalValueToDefaultUnitValue(this.value as number, this.utilizationItem!.unitGroup);
                ret = unitStr.value + ' ' + this.unit.getUnitStrings(unitStr.unit);
            }
        }
        else {
            ret = this.value as string;
        }

        if (ret === undefined) {
            ret = '-';
        }

        return ret;
    }

    public getPercentageBarStyle(): string {
        const val = this.value as number;
        if (val > 100 || this.propertyType === PropertyType.InfinityPlus || this.propertyType === PropertyType.InfinityMinus || this.propertyType === PropertyType.NaN) {
            return 'invalid-progress-percent-container';
        }

        return 'progress-percent-container';
    }

    public getNumberContainerStyle(): string {
        if (this.propertyType === PropertyType.Boolean) {
            if (this.value as boolean) {
                return 'invalid-value-number';
            }
            return 'number';
        }
        else if (this.unitGroup == UnitGroup.Percentage) {
            if (this.value as number > 100) {
                return 'invalid-value-number';
            }
            return 'number';
        }
        else if (this.propertyType === PropertyType.InfinityPlus || this.propertyType === PropertyType.InfinityMinus || this.propertyType === PropertyType.NaN) {
            return 'invalid-value-number';
        }

        return 'classic-value-number';
    }

    public showPercentBar(): boolean {
        return this.unitGroup == UnitGroup.Percentage || this.propertyType === PropertyType.NaN || this.propertyType === PropertyType.InfinityPlus || this.propertyType === PropertyType.InfinityMinus;
    }
}
