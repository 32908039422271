import { Injectable } from '@angular/core';
import { CodeList, ICodeListTextDeps } from '@profis-engineering/pe-ui-common/entities/code-lists/code-list';
import { Design, IDesignStateBase } from '@profis-engineering/pe-ui-common/entities/design';
import { IButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/button-group-props';
import { IButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/button-props';
import { ICheckboxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/checkbox-props';
import { IDropdownProps } from '@profis-engineering/pe-ui-common/entities/main-menu/dropdown-props';
import { IImageNameRadioGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-radio-group-props';
import { IImageNameSelectionGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/image-name-selection-group-props';
import { IMainMenuControl, IMenu } from '@profis-engineering/pe-ui-common/entities/main-menu/menu';
import { BaseControl, Button, DropDown, ImageNameSelectionGroup, Menu, TextBox, ToggleButtonGroup, UIPropertyBaseControl } from '@profis-engineering/pe-ui-common/entities/main-menu/navigation';
import { IPopupGridPartialProps } from '@profis-engineering/pe-ui-common/entities/main-menu/popup-grid-props';
import { ITextBoxProps } from '@profis-engineering/pe-ui-common/entities/main-menu/textbox-props';
import { IToggleButtonGroupItem } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-group-props';
import { IToggleButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-button-props';
import { IToggleImageButtonProps } from '@profis-engineering/pe-ui-common/entities/main-menu/toggle-image-button-props';
import { IModalGridComponentInput, IModalGridItem } from '@profis-engineering/pe-ui-common/entities/modal-grid';
import { IIconStyle } from '@profis-engineering/pe-ui-common/helpers/image-helper';
import { IModalOpened, ModalOptions } from '@profis-engineering/pe-ui-common/helpers/modal-helper';
import { UnitType } from '@profis-engineering/pe-ui-common/helpers/unit-helper';
import { ICalculationResult } from '@profis-engineering/pe-ui-common/services/calculation.common';
import { ILocalizationExtension } from '@profis-engineering/pe-ui-common/services/extensions.common';
import { IMenuServiceCodeListIdWithItems, IMenuServiceDropdownItemProps, IMenuServiceExtensions, MenuServiceBase, TabItem } from '@profis-engineering/pe-ui-common/services/menu.common';
import { PickKeysByValue } from 'ts-essentials/dist/pick-keys-by-value';
import { ProductSelectionInput } from '../components/product-selection/product-selection.component';
import { getSpriteAsIconStyle } from '../sprites';
import { DataService } from './data.service';
import { DesignDetails, DesignService, DesignTypeId, designTypeSwitch, PropertyIdValue, StrengthDesignDetails } from './design.service';
import { FavoritesService } from './favorites.service';
import { LocalizationService } from './localization.service';
import { NumberService } from './number.service';
import { StaticMenuService } from './static-menu.service';
import { UnitService } from './unit.service';
import { UserService } from './user.service';

export interface CommonCodeListItem {
    id: number;
    name: string;
    image: string;
    imageStyle?: IIconStyle;
}

interface CodeListEntity {
    id: number;
    name?: string;
    nameKey?: string;
    image?: string;
    tooltipTitleKey?: string;
    tooltipKey?: string;
}

export interface CodeLists {
    region: CodeList[];
    designStandard: CodeList[];
    postInstalledReinforcementDesign: CodeList[];
    beta: CodeList[];
    loadType: CodeList[];
    baseMaterial: CodeList[];
    aggregateSizes: CodeList[];
    reinforcementArrangements: CodeList[];
    zonesNumber: CodeList[];
    openingsNumber: CodeList[];
    drillingTypes: CodeList[];
    holeTypes: CodeList[];
    drillingAids: CodeList[];
    installationDirections: CodeList[];
    concreteMembers: CodeList[];
    compressionMembers: CodeList[];
    baseMembers: CodeList[];
    unitLength: CodeList[];
    unitArea: CodeList[];
    unitStress: CodeList[];
    unitForce: CodeList[];
    unitMoment: CodeList[];
    unitTemperature: CodeList[];
    unitForcePerLength: CodeList[];
    unitDensity: CodeList[];
    unitAreaPerLength: CodeList[];
    fastenerFamilyGroups: CodeList[];
    fastenerFamilies: CodeList[];
    fasteners: CodeList[];
    columnPositions: CodeList[];
}

class CodeListItem extends CodeList { }

export type CodeListType = keyof CodeLists;

export interface SetMenuOptions {
    propertyChange: (propertyChanges: PropertyIdValue[]) => Promise<void>;
}

@Injectable({
    providedIn: 'root'
})
export class MenuService implements IMenuServiceExtensions {
    private baseService!: MenuServiceBase;

    public codeLists: CodeLists = {} as CodeLists;

    private strengthCodeLists: CodeLists = {} as CodeLists;
    private punchCodeLists: CodeLists = {} as CodeLists;

    private propertyChange!: (propertyChanges: PropertyIdValue[]) => Promise<void>;

    constructor(
        private localizationService: LocalizationService,
        private staticMenuService: StaticMenuService,
        private dataService: DataService,
        private userService: UserService,
        private designService: DesignService,
        private numberService: NumberService,
        private favoritesService: FavoritesService,
        private unitService: UnitService
    ) { }

    private get designDetails(): DesignDetails {
        return this.userService.design.designDetails;
    }

    public init(): void {
        this.initCodeLists(this.strengthCodeLists);
        this.initCodeLists(this.punchCodeLists);
        this.strengthInitCodeLists();
        this.punchInitCodeLists();
    }

    public setBaseService(baseService: MenuServiceBase): void {
        this.baseService = baseService;
    }

    public get menuExtensions(): IMenuServiceExtensions {
        return this.baseService.menuExtensions;
    }

    public setMenu(options: SetMenuOptions): void {
        this.propertyChange = options.propertyChange;

        // First reset menu extensions on base service
        this.baseService.setMenuExtensions?.(null!); // Pass null to ensure it's reset to default values

        this.menuExtensions.localizationExtension = {
            getTranslation: this.localizationService.getString.bind(this.localizationService),
            checkForTranslation: this.localizationService.hasTranslation.bind(this.localizationService)
        } as ILocalizationExtension;

        this.menuExtensions.getMenuStructure = this.getMenuStructure.bind(this);
        this.menuExtensions.getMenuCommands = this.getMenuCommands.bind(this);
        this.menuExtensions.getMenuModals = this.getMenuModals.bind(this);

        this.menuExtensions.calculateAsync = this.calculateAsync.bind(this);
        this.menuExtensions.trackUsage = this.trackUsage.bind(this);

        this.menuExtensions.getDesignCodeList = this.getDesignCodeList.bind(this);
        this.menuExtensions.getProjectCodeList = this.getProjectCodeList.bind(this);
        this.menuExtensions.getCodeListItems = this.getCodeListItems.bind(this);
        this.menuExtensions.getCodeListIdWithItems = this.getCodeListIdWithItems.bind(this);
        this.menuExtensions.getCodeListItemText = this.getCodeListItemText.bind(this);
        this.menuExtensions.getDesignStandard = this.getDesignStandard.bind(this);

        this.menuExtensions.getTabControlId = this.getTabControlId.bind(this);
        this.menuExtensions.updateTabs = this.updateTabs.bind(this);
        this.menuExtensions.isFavoritesTabHidden = this.isFavoritesTabHidden.bind(this);

        this.menuExtensions.getRegionDisplayId = this.getRegionDisplayId.bind(this);
        this.menuExtensions.getRegionId = this.getRegionId.bind(this);
        this.menuExtensions.getRegionFavoritesId = this.getRegionFavoritesId.bind(this);
        this.menuExtensions.setRegionKbFields = this.setRegionKbFields.bind(this);

        this.menuExtensions.clientHidden = this.clientHidden.bind(this);

        this.menuExtensions.formatDisplayStringModule = this.formatDisplayStringModule.bind(this);
        this.menuExtensions.getLocalizedStringWithTranslationFormat = this.getLocalizedStringWithTranslationFormat.bind(this);

        this.menuExtensions.createMainMenuControlModule = this.createMainMenuControlModule.bind(this);

        this.menuExtensions.overrideTextBoxProps = this.overrideTextBoxProps.bind(this);
        this.menuExtensions.overrideTextBoxUnitGroup = this.overrideTextBoxUnitGroup.bind(this);
        this.menuExtensions.setTextBoxOnStateChanged = this.setTextBoxOnStateChanged.bind(this);

        this.menuExtensions.overrideDropdownProps = this.overrideDropdownProps.bind(this);
        this.menuExtensions.getDropdownItemProps = this.getDropdownItemProps.bind(this);
        this.menuExtensions.setDropdownOnAllowedValuesChanged = this.setDropdownOnAllowedValuesChanged.bind(this);
        this.menuExtensions.setDropdownOnStateChanged = this.setDropdownOnStateChanged.bind(this);

        this.menuExtensions.overrideCheckboxProps = this.overrideCheckboxProps.bind(this);

        this.menuExtensions.overrideCheckboxGroupProps = this.overrideCheckboxGroupProps.bind(this);

        this.menuExtensions.overrideRadioButtonProps = this.overrideRadioButtonProps.bind(this);

        this.menuExtensions.overrideRadioButtonGroupProps = this.overrideRadioButtonGroupProps.bind(this);
        this.menuExtensions.getApplySortOrderItemIds = this.getApplySortOrderItemIds.bind(this);
        this.menuExtensions.getRadioGroupParentId = this.getRadioGroupParentId.bind(this);

        this.menuExtensions.overrideButtonProps = this.overrideButtonProps.bind(this);

        this.menuExtensions.overrideButtonGroupProps = this.overrideButtonGroupProps.bind(this);
        this.menuExtensions.updateButtonGroupItemProps = this.updateButtonGroupItemProps.bind(this);

        this.menuExtensions.overrideToggleButtonProps = this.overrideToggleButtonProps.bind(this);

        this.menuExtensions.overrideToggleImageButtonProps = this.overrideToggleImageButtonProps.bind(this);

        this.menuExtensions.overrideToggleButtonGroupProps = this.overrideToggleButtonGroupProps.bind(this);
        this.menuExtensions.updateToggleButtonGroupItemCodeListProps = this.updateToggleButtonGroupItemCodeListProps.bind(this);
        this.menuExtensions.updateToggleButtonGroupItemProps = this.updateToggleButtonGroupItemProps.bind(this);
        this.menuExtensions.getToggleButtonGroupAllowedValues = this.getToggleButtonGroupAllowedValues.bind(this);

        this.menuExtensions.overrideGroupProps = this.overrideGroupProps.bind(this);

        this.menuExtensions.overrideLabelProps = this.overrideLabelProps.bind(this);

        this.menuExtensions.overrideRotateProps = this.overrideRotateProps.bind(this);

        this.menuExtensions.overridePopupGridProps = this.overridePopupGridProps.bind(this);
        this.menuExtensions.customizePopupGridModal = this.customizePopupGridModal.bind(this);
        this.menuExtensions.customizePopupGridItems = this.customizePopupGridItems.bind(this);
        this.menuExtensions.getPopupGridHideShowDescriptionOnButton = this.getPopupGridHideShowDescriptionOnButton.bind(this);

        this.menuExtensions.overridePopupGridPartialProps = this.overridePopupGridPartialProps.bind(this);
        this.menuExtensions.customizePopupGridPartialControl = this.customizePopupGridPartialControl.bind(this);
        this.menuExtensions.customizePopupGridPartialItems = this.customizePopupGridPartialItems.bind(this);
        this.menuExtensions.customizePopupGridPartialModal = this.customizePopupGridPartialModal.bind(this);

        this.menuExtensions.overrideDlubalImportExportProps = this.overrideDlubalImportExportProps.bind(this);
        this.menuExtensions.overrideSAP2000ImportExportProps = this.overrideSAP2000ImportExportProps.bind(this);
        this.menuExtensions.overrideRobotImportExportProps = this.overrideRobotImportExportProps.bind(this);
        this.menuExtensions.overrideETABSImportExportProps = this.overrideETABSImportExportProps.bind(this);
        this.menuExtensions.overrideStaadProImportExportProps = this.overrideStaadProImportExportProps.bind(this);

        this.menuExtensions.overrideImageNameRadioGroupProps = this.overrideImageNameRadioGroupProps.bind(this);
        this.menuExtensions.updateImageNameRadioGroupItemProps = this.updateImageNameRadioGroupItemProps.bind(this);
        this.menuExtensions.updateImageNameRadioGroupSortOrder = this.updateImageNameRadioGroupSortOrder.bind(this);

        this.menuExtensions.overrideImageNameSelectionGroupProps = this.overrideImageNameSelectionGroupProps.bind(this);
        this.menuExtensions.updateImageNameSelectionGroupItemProps = this.updateImageNameSelectionGroupItemProps.bind(this);

        this.menuExtensions.overrideSwitchWithDescriptionProps = this.overrideSwitchWithDescriptionProps.bind(this);

        this.menuExtensions.overrideRangeSliderProps = this.overrideRangeSliderProps.bind(this);

        this.menuExtensions.overrideTabGroupProps = this.overrideTabGroupProps.bind(this);
        this.menuExtensions.overrideTabGroupItems = this.overrideTabGroupItems.bind(this);
        this.menuExtensions.getTabGroupItemsByTag = this.getTabGroupItemsByTag.bind(this);
        this.menuExtensions.setFooterControlVisibility = this.setFooterControlVisibility.bind(this);
        this.menuExtensions.getButtonDisplayText = this.getButtonDisplayText.bind(this);
    }

    private initCodeLists(codeLists: CodeLists) {
        this.initUnits(codeLists);

        codeLists.designStandard = this.toCodeList(this.dataService, 'designStandards');
        codeLists.postInstalledReinforcementDesign = this.toCodeList(this.dataService, 'postInstalledReinforcementDesigns');
        codeLists.loadType = this.toCodeList(this.dataService, 'loadTypes');
        codeLists.baseMaterial = this.toCodeList(this.dataService, 'baseMaterials');
        codeLists.zonesNumber = this.toCodeList(this.dataService, 'zonesNumbers');
        codeLists.openingsNumber = this.toCodeList(this.dataService, 'openingsNumbers');
        codeLists.drillingTypes = this.toCodeList(this.dataService, 'drillingTypes');
        codeLists.holeTypes = this.toCodeList(this.dataService, 'holeTypes');
        codeLists.drillingAids = this.toCodeList(this.dataService, 'drillingAids');
        codeLists.installationDirections = this.toCodeList(this.dataService, 'installationDirections');
        codeLists.concreteMembers = this.toCodeList(this.dataService, 'concreteMembers');

        codeLists.fastenerFamilyGroups = this.toCodeList(this.dataService, 'fastenerFamilyGroups');
        codeLists.fastenerFamilies = this.toCodeList(this.dataService, 'fastenerFamilies');
        codeLists.fasteners = this.toCodeList(this.dataService, 'fasteners');
        codeLists.aggregateSizes = this.toCodeList(this.dataService, 'aggregateSizes');
        codeLists.reinforcementArrangements = this.toCodeList(this.dataService, 'reinforcementArrangements');
    }

    private strengthInitCodeLists() {
        this.strengthCodeLists.region = this.toCodeList(this.dataService, 'strengthRegions');
    }

    private punchInitCodeLists() {
        this.punchCodeLists.region = this.toCodeList(this.dataService, 'punchRegions');

        this.punchCodeLists.baseMembers = this.toCodeList(this.dataService, 'baseMembers');
        this.punchCodeLists.compressionMembers = this.toCodeList(this.dataService, 'compressionMembers');
        this.punchCodeLists.beta = this.toCodeList(this.dataService, 'betaValues');
        this.punchCodeLists.columnPositions = this.toCodeList(this.dataService, 'columnPositions');
    }

    private initUnits(codeLists: CodeLists) {
        // only one units codeLists for strength and punch
        // we might change this in the future if we will have different unit filters (like no cm in length unit group)
        codeLists.unitLength = this.toCodeList(this.dataService.units, 'length');
        codeLists.unitArea = this.toCodeList(this.dataService.units, 'area');
        codeLists.unitStress = this.toCodeList(this.dataService.units, 'stress');
        codeLists.unitForce = this.toCodeList(this.dataService.units, 'force');
        codeLists.unitMoment = this.toCodeList(this.dataService.units, 'moment');
        codeLists.unitTemperature = this.toCodeList(this.dataService.units, 'temperature');
        codeLists.unitForcePerLength = this.toCodeList(this.dataService.units, 'forcePerLength');
        codeLists.unitDensity = this.toCodeList(this.dataService.units, 'density');
        codeLists.unitAreaPerLength = this.toCodeList(this.dataService.units, 'areaPerLength');
    }

    private toCodeList<T>(obj: T, property: PickKeysByValue<T, CodeListEntity[]>): CodeList[] {
        const values = obj?.[property] as CodeListEntity[] | undefined;

        if (values == null) {
            console.warn(`missing code list ${property as string}`);
            return [];
        }

        return values.map(x => new CodeListItem({
            id: x.id,
            name: x.name,
            nameResourceKey: x.nameKey,
            image: x.image,
            tooltipDisplayKey: x.tooltipKey,
            tooltipTitleDisplayKey: x.tooltipTitleKey
        }));
    }

    public getMenuStructure(): Menu<BaseControl<string>, string> | undefined {
        this.codeLists = designTypeSwitch(this.designDetails.designTypeId,
            () => this.strengthCodeLists,
            () => this.punchCodeLists,
        );

        return designTypeSwitch(this.designDetails.designTypeId,
            () => this.staticMenuService.getStrengthMenu(),
            () => this.staticMenuService.getPunchMenu(),
        );
    }

    public getMenuCommands(): Record<string, (navigationControl?: BaseControl<string> | undefined) => void> {
        return {};
    }

    public getMenuModals(): Record<number, (input?: object | undefined) => IModalOpened> {
        return {};
    }

    // Common
    public async calculateAsync(design: Design, changeFn?: ((design: Design) => void) | undefined): Promise<ICalculationResult> {
        changeFn?.(design);

        // get changes
        design.modelChanges.observe();
        const changes = design.modelChanges.changes;
        design.modelChanges.clear();

        // call update if we have changes
        if (changes.length > 0) {
            const propertyChanges = changes.map((x): PropertyIdValue => ({
                propertyId: x.name,
                propertyValue: x.newValue
            }));
            await this.propertyChange(propertyChanges);
        }

        return undefined as unknown as ICalculationResult;
    }

    public trackUsage() {
        // not needed
    }

    public getDesignCodeList(codelistName: string): number {
        // why does menu need a number id for a codelist?
        return codelistName as unknown as number;
    }

    public getProjectCodeList(codelistName: string): number {
        // why does menu need a number id for a codelist?
        return codelistName as unknown as number;
    }

    public getCodeListItems(navigationControl: UIPropertyBaseControl<string>, design: Design, codeList: number): CodeList[] | undefined {
        if (codeList != null) {
            return this.codeLists[codeList as unknown as CodeListType] ?? [];
        }

        this.logMissingCodeList(navigationControl, design);
        return undefined;
    }

    public getCodeListIdWithItems(design: Design, navigationControl: UIPropertyBaseControl<string>): IMenuServiceCodeListIdWithItems | undefined {
        const codeListItems = this.codeLists[navigationControl.CodelistName as CodeListType] ?? [];

        // This is code is here, because Popup Grid component does not support any kind of sorting, so we make sure we send already sorted items to it
        // Once Popup Grid component supports sorting, this can be removed (but component also has to first be moved from pe-ui-pe to pe-ui-common...)
        if (navigationControl.CodelistName == 'fastenerFamilyGroups') {
            const fastenerFamilyGroupsById = this.dataService.fastenerFamilyGroupsById;
            this.sortFastenerCodeListItemsByRegionSortNo(codeListItems, fastenerFamilyGroupsById, design.regionId);
        }

        if (navigationControl.CodelistName == 'fastenerFamilies') {
            const fastenerFamilies = this.dataService.fastenerFamiliesById;
            this.sortFastenerCodeListItemsByRegionSortNo(codeListItems, fastenerFamilies, design.regionId);
        }

        if (navigationControl.CodelistName == 'designStandard') {
            const designTypeId = design.designTypeId as DesignTypeId;
            const allowedValues = this.dataService.getPropertyDetail('designStandardId', { designTypeId, regionId: design.regionId })?.allowedValues ?? [];
            const items = this.codeLists.designStandard.filter(cl => allowedValues.some(id => id == cl.id));
            return {
                codeList: navigationControl.CodelistName as unknown as number,
                codeListItems: items
            };
        }
        return {
            codeList: navigationControl.CodelistName as unknown as number,
            codeListItems: codeListItems
        };
    }

    /**
     * Sorts fastener family groups and fastener families.
     *
     * The sorting is based on the value of "regionalSortNumbers[design.regionId]" for each item.
     */
    private sortFastenerCodeListItemsByRegionSortNo(codeListItems: CodeList[], dataById: Record<number, { id: number; regionalSortNumbers?: Record<number, number> }>, regionId: number) {
        codeListItems.sort((a, b) => {
            const aItem = dataById[a.id];
            const bItem = dataById[b.id];

            const aSortNumber = aItem.regionalSortNumbers?.[regionId] ?? Number.MAX_SAFE_INTEGER;
            const bSortNumber = bItem.regionalSortNumbers?.[regionId] ?? Number.MAX_SAFE_INTEGER;

            return aSortNumber - bSortNumber;
        });
    }

    private sortFastenerCommonCodeListItemsByRegionSortNo(commonCodeListItems: { id: number }[], dataById: Record<number, { id: number; regionalSortNumbers?: Record<number, number> }>, regionId: number) {
        commonCodeListItems.sort((a, b) => {
            const aItem = dataById[a.id];
            const bItem = dataById[b.id];

            const aSortNumber = aItem.regionalSortNumbers?.[regionId] ?? Number.MAX_SAFE_INTEGER;
            const bSortNumber = bItem.regionalSortNumbers?.[regionId] ?? Number.MAX_SAFE_INTEGER;

            return aSortNumber - bSortNumber;
        });
    }

    public getCodeListItemText(design: Design, codeList: number, codeListItem: CodeList, codeListDeps: ICodeListTextDeps): string | undefined {
        const codeListType = codeList as unknown as CodeListType;

        if (codeListType == 'fastenerFamilies') {
            const fastenerFamily = this.dataService.fastenerFamiliesById[codeListItem.id];
            return fastenerFamily?.regionalNames?.[design.regionId] ?? codeListItem.name;
        }

        if (codeListType == 'aggregateSizes') {
            const selectedUnit = (this.designDetails as StrengthDesignDetails).properties.unitLength;
            const unit = this.codeLists.unitLength.find(x => x.id == selectedUnit);
            const unitStr = unit ? ' ' + unit.name : '';
            return this.unitService.convertUnitValueArgsToUnit(Number(codeListItem.name), UnitType.mm, selectedUnit, true).toString() + unitStr;
        }

        return codeListItem.getTranslatedNameText(codeListDeps);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public getDesignStandard(design: Design): CodeList | undefined {
        return undefined;
    }

    public getTabControlId(name: string): string | undefined {
        return name;
    }


    public updateTabs() {
        // not needed
    }

    public isFavoritesTabHidden() {
        return false;
    }

    public isTabDisabled(): boolean {
        return false;
    }

    public getRegionDisplayId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionId(tabName: string, regionName: string): string | undefined {
        return `${tabName}-${regionName}`;
    }

    public getRegionFavoritesId(id: string): string | undefined {
        return this.favoritesService.getMenuRegionIdFavorites(id, this.designDetails.designTypeId);
    }

    public setRegionKbFields() {
        // not needed
    }

    public clientHidden() {
        return false;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public formatDisplayStringModule(textKey: string, design: Design, codeList?: number | undefined, uiPropertyId?: number | undefined): string | undefined {
        return this.localizationService.getString(textKey);
    }

    public getLocalizedStringWithTranslationFormat(): string {
        return '';
    }

    public createMainMenuControlModule(): IMainMenuControl | undefined {
        return undefined;
    }

    public overrideTextBoxProps() {
        // not needed
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public overrideTextBoxUnitGroup(design: Design, controlProps: ITextBoxProps, navigationControl: TextBox<string>, setState?: (() => void) | undefined): void {
        // not needed
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public setTextBoxOnStateChanged(state: IDesignStateBase, controlProps: ITextBoxProps, navigationControl: TextBox<string>): void {
        // not needed
    }

    public overrideDropdownProps() {
        // not needed
    }

    public getDropdownItemProps(): IMenuServiceDropdownItemProps | undefined {
        return {};
    }

    public setDropdownOnAllowedValuesChanged() {
        // not needed
    }

    public setDropdownOnStateChanged(
        design: Design,
        designCodeList: number,
        state: IDesignStateBase,
        _menu: IMenu,
        navigationControl: DropDown<string>,
        _controlProps: IDropdownProps,
        onStateChangeFn: (formatTextFn: (codeListItem: CodeList, unit?: UnitType | undefined) => string, unit?: UnitType | undefined) => IDropdownProps
    ): IDropdownProps | undefined {
        return onStateChangeFn((codeListItem) => this.getCodeListItemText(design, designCodeList, codeListItem, {
            localizationService: undefined,
            numberService: this.numberService,
            getLocalizedString: (key, opts) => this.localizationService.getString(key, opts)
        })!, undefined);
    }

    public overrideCheckboxProps(_design: Design, controlProps: ICheckboxProps) {
        if (controlProps.iconImage != null) {
            controlProps.iconImageStyle = getSpriteAsIconStyle(controlProps.iconImage);
            controlProps.iconImageSelectedStyle = getSpriteAsIconStyle(`${controlProps.iconImage}-selected`);
        }
    }

    public overrideCheckboxGroupProps() {
        // not needed
    }

    public overrideRadioButtonProps() {
        // not needed
    }

    public overrideRadioButtonGroupProps() {
        // not needed
    }

    public getApplySortOrderItemIds(): number[] | undefined {
        return undefined;
    }

    public getRadioGroupParentId(): number | undefined {
        return undefined;
    }

    public overrideButtonProps(design: Design, controlProps: IButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }
    }

    public overrideButtonGroupProps() {
        // not needed
    }

    public updateButtonGroupItemProps(design: Design, navigationControl: Button, item: IButtonGroupItem) {
        if (item.image != null && item.image != '') {
            item.imageStyle = getSpriteAsIconStyle(item.image);
        }
    }

    public overrideToggleButtonProps(_design: Design, controlProps: IToggleButtonProps) {
        controlProps.imageStyle = this.getIconStyleForImage(controlProps.image);
    }

    public overrideToggleImageButtonProps(design: Design, controlProps: IToggleImageButtonProps) {
        if (controlProps.image != null) {
            controlProps.imageStyle = getSpriteAsIconStyle(controlProps.image);
        }

        if (controlProps.alternateImage != null) {
            controlProps.alternateImageStyle = getSpriteAsIconStyle(controlProps.alternateImage);
        }
    }

    public overrideToggleButtonGroupProps() {
        // not needed
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    public updateToggleButtonGroupItemCodeListProps(design: Design, navigationControl: ToggleButtonGroup<string>, codeListItem: CodeList): void {
        // not needed
    }

    public updateToggleButtonGroupItemProps(design: Design, navigationControl: ToggleButtonGroup, item: IToggleButtonGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);

        // for load types button group, we want to display only images on buttons and no text
        const codeListType = navigationControl.CodelistName as CodeListType;
        if (codeListType == 'loadType')
            item.text = null!;
    }

    public getToggleButtonGroupAllowedValues(codeList: number, allowedValues: number[]): number[] {
        return allowedValues;
    }

    public overrideGroupProps() {
        // not needed
    }

    // Label
    public overrideLabelProps() {
        // not needed
    }

    // Rotate
    public overrideRotateProps() {
        // not needed
    }

    // PopupGrid
    public overridePopupGridProps() {
        // not needed
    }

    public customizePopupGridModal(modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions, codeList: number): void {
        modalOpts.size = 'lg';

        const codeListType = codeList as unknown as CodeListType;

        if (codeListType == 'fastenerFamilyGroups') {
            modalProps.componentName = 'sp-product-selection';

            const productSelectionInput = modalProps as ProductSelectionInput;
            productSelectionInput.selectedProductId = this.designDetails.properties.fastenerFamilyGroupId;
            productSelectionInput.products = Object.values(this.filterProductSelection());
            this.sortFastenerCommonCodeListItemsByRegionSortNo(productSelectionInput.products, this.dataService.fastenerFamilyGroupsById, this.designDetails.regionId);
            productSelectionInput.propertyChange = this.propertyChange;
        }
    }

    public filterProductSelection(): Record<number, CommonCodeListItem> {
        const codeListItems = this.codeLists.fastenerFamilyGroups;
        const items = {} as Record<number, CommonCodeListItem>;

        for (const codeListItem of codeListItems) {
            const item = {} as CommonCodeListItem;
            const fastenerFamilyGroup = codeListItem.id != null ? this.dataService.fastenerFamilyGroupsById[codeListItem.id] : undefined;
            item.id = codeListItem.id;
            item.name = fastenerFamilyGroup?.regionalNames?.[this.designDetails.regionId] ?? codeListItem.name ?? '';

            if (codeListItem.image != null && codeListItem.image != '') {
                item.image = 'sprite-product-ui-' + codeListItem.image;
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }

            items[codeListItem.id] = item;
        }

        return items;
    }

    public customizePopupGridItems(items: IModalGridItem<number>[], design: Design, codelist: number): IModalGridItem<number>[] {
        const codeListType = codelist as unknown as CodeListType;

        if (items != null && codeListType == 'fastenerFamilyGroups') {
            const codeListItems = this.filterProductSelection();

            for (const item of items) {
                if (item.value != null) {
                    const codeListItem = codeListItems[item.value];
                    item.name = codeListItem.name;
                    if (codeListItem.image != null && codeListItem.image != '') {
                        item.image = codeListItem.image;
                        item.imageStyle = codeListItem.imageStyle;
                    }
                }
            }
        }

        return items;
    }

    public getPopupGridHideShowDescriptionOnButton() {
        return false;
    }

    public overridePopupGridPartialProps() {
        // not needed
    }

    public customizePopupGridPartialControl(controlProps: IPopupGridPartialProps) {
        controlProps.numberOfButtons = 3;
    }

    public customizePopupGridPartialItems(items: IModalGridItem<number>[]): IModalGridItem<number>[] {
        items?.forEach(item => {
            if (item.image != null && item.image != '') {
                item.imageStyle = {
                    elementStyle: getSpriteAsIconStyle(item.image)
                };
            }
        });

        return items;
    }

    public customizePopupGridPartialModal(_modalProps: IModalGridComponentInput<IModalGridItem<number>>, modalOpts: ModalOptions) {
        modalOpts.size = 'lg';
    }

    public overrideDlubalImportExportProps() {
        // not needed
    }

    public overrideSAP2000ImportExportProps() {
        // not needed
    }

    public overrideRobotImportExportProps() {
        // not needed
    }

    public overrideETABSImportExportProps() {
        // not needed
    }

    public overrideStaadProImportExportProps() {
        // not needed
    }

    public overrideImageNameRadioGroupProps() {
        // not needed
    }

    public updateImageNameRadioGroupItemProps(_design: Design, _navigationControl: UIPropertyBaseControl, item: IImageNameRadioGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public updateImageNameRadioGroupSortOrder(): Promise<void> {
        return Promise.resolve();
    }

    public overrideImageNameSelectionGroupProps() {
        // not needed
    }

    public updateImageNameSelectionGroupItemProps(_design: Design, _navigationControl: ImageNameSelectionGroup, item: IImageNameSelectionGroupItem) {
        item.imageStyle = this.getIconStyleForImage(item.image);
    }

    public overrideSwitchWithDescriptionProps() {
        // not needed
    }

    public overrideRangeSliderProps() {
        // not needed
    }

    public overrideTabGroupProps() {
        // not needed
    }

    public overrideTabGroupItems() {
        // not needed
    }

    public getTabGroupItemsByTag(childNavigationControls: UIPropertyBaseControl<string>[], tab: TabItem): UIPropertyBaseControl<string>[] {
        return childNavigationControls.filter(x => x.ParentControlTag == tab.Tag);
    }

    public setFooterControlVisibility(design: Design, menu: IMenu): IMenu | undefined {
        return menu;
    }

    private logMissingCodeList(navigationControl: UIPropertyBaseControl, design: Design) {
        const isVisible = navigationControl.UIPropertyId == null || !design.properties.get(navigationControl.UIPropertyId).hidden;

        if (isVisible) {
            console.warn('Missing code list: %s', navigationControl.CodelistName);
        }
    }

    private getIconStyleForImage(image: string | undefined): IIconStyle | undefined {
        if (image == null || image == '') {
            return undefined;
        }

        return getSpriteAsIconStyle(image);
    }

    public getButtonDisplayText(design: Design, navigationControl: Button<string>): string | undefined {
        return navigationControl.DisplayKey ? this.localizationService.getString(navigationControl.DisplayKey) : undefined;
    }

}
