<div id="utilizations" class="utilizations box-section box-section-white" *ngIf="this.utilizationsAvailable">
    <div [id]="getComponentId('utilization')">
        <ng-container *ngIf="utilizations">
            <ng-container *ngFor="let section of utilizations!.sections; index as sectionIndex">
                <div class="box-section-header box-section-header-with-img" [class]="typeClass(TabType.Primary)">

                    <div class="box-section-title drag-handle-static utilization-title-wrapper" [ngbTooltip]="section.tooltipText">
                        <div class="image" [id]="getComponentId('utilization-image')" *ngIf="section.iconName != null">
                            <div class="pe-ui-sp-sprite pe-ui-sp-sprite-tab-{{section.iconName}}"></div>
                        </div>

                        <span class="bar-subtitle"></span>
                        <span class="bar-title" [id]="getComponentId('utilization-header')">
                            {{ translate(section.title) }}
                            <span class="new-label" *ngIf="showNewLabel" l10n="Common.Tags.New"></span>
                        </span>
                        <span class="bar-subtitle"></span>
                    </div>

                    <pe-utilization-bar [id]="getComponentId('utilization-bar')" [collapsed]="getIsCollapsed(section.id)" [utilizationInvalid]="!section.isMaxUtilizationValid" [utilizationValue]="roundValue(section.maxUtilization)" />
                    <pe-collapse [id]="section.id" [collapsed]="getIsCollapsed(section.id)" (collapsedChange)="onCollapseChanged($any($event).detail, section.id)" />
                </div>
                <ng-container *ngFor="let subsection of section.subsections; index as subsectionIndex">

                    <div [id]="getComponentId('secondary-type', sectionIndex)" class="box-section-header box-section-header-with-img" [class]="typeClass(TabType.Secondary)" *ngIf="(subsection.isVisible ?? true) && !getIsCollapsed(section.id)">

                        <div class="box-section-title drag-handle-static utilization-title-wrapper" [ngbTooltip]="null">
                            <div *ngIf="subsection.iconName != null" class="pe-ui-sp-sprite pe-ui-sp-sprite-tab-{{subsection.iconName}}"></div>
                            <span class="bar-title" [id]="getComponentId('utilization-details-header', sectionIndex)">
                                {{ translate(subsection.title) }}
                                <span class="new-label" *ngIf="showNewLabel" l10n="Common.Tags.New"></span>
                            </span>

                            <span class="bar-subtitle"></span>
                        </div>

                        <pe-utilization-bar [collapsed]="getIsCollapsed(subsection.id)" [utilizationInvalid]="!subsection.isMaxUtilizationValid" [utilizationValue]="roundValue(subsection.maxUtilization)" [id]="getComponentId('utilization-bar', subsectionIndex)" />
                        <pe-collapse [id]="subsection.id" [collapsed]="getIsCollapsed(subsection.id)" (collapsedChange)="onCollapseChanged($any($event).detail, subsection.id)"/>
                    </div>
                    <ng-container *ngIf="(subsection.isVisible ?? true) && !getIsCollapsed(subsection.id) && !getIsCollapsed(section.id)">
                        <div class="items box-section-content" *ngIf="subsection.generalUtilizationItems != null && subsection.generalUtilizationItems.length > 0">

                            <ng-container *ngFor="let utilizationItem of subsection.generalUtilizationItems; index as utilizationIndex">

                                <div [id]="getComponentId('utilization-group-details-property', subsectionIndex, utilizationIndex)">

                                    <app-utilization-item
                                        [title]="getPropertyTitle(utilizationItem.title, utilizationItem.utilizationVariable)"
                                        [controlId]="getComponentId('utilization-detail-worst', subsectionIndex, utilizationIndex)!"
                                        [utilizationItem]="utilizationItem">
                                    </app-utilization-item>

                                </div>

                            </ng-container>

                       </div>
                    </ng-container>

                </ng-container>
            </ng-container>
        </ng-container>
    </div>
</div>