import { StandardMaterial } from '@babylonjs/core/Materials/standardMaterial';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import { CreatePlane } from '@babylonjs/core/Meshes/Builders/planeBuilder';
import { Mesh } from '@babylonjs/core/Meshes/mesh';
import { PunchComponent, PunchComponentConstructor } from '../../punch-component';

const NUMBER_OF_SIDES = 4;
const BASE_MATERIAL_COLOR = Color3.FromHexString('#cbcabf');
const TRANSPARENT_ALPHA = 0.75;
const OPAQUE_ALPHA = 1;

enum SidePosition {
    Left,
    Top,
    Right,
    Bottom
}

interface SideInfo {
    name: string;
    index: number;
    sideDimension: SideDimension;
    color: Color3;
}

interface SideDimension {
    rotation: number;
    x: number;
    y: number;
    length: number;
}

interface OpeningInfo {
    length: number;
    width: number;
    originX: number;
    originY: number;
}

export class PunchOpening extends PunchComponent {
    private sideMeshes: Mesh[];

    constructor(ctor: PunchComponentConstructor) {
        super(ctor);
        this.sideMeshes = new Array<Mesh>(4);
    }

    public update(): void {
        this.ensureMesh();
    }

    private ensureMesh() {
        for (const zoneMesh of this.sideMeshes) {
            zoneMesh?.setEnabled(false);
        }

        const sideInfos = this.getSideInfo();
        for (const side of sideInfos) {
            this.sideMeshes[side.index - 1] = this.createSideMesh(side);
        }
    }

    private getSideInfo() {
        const openings: OpeningInfo[] = [
            { length: this.model.baseMaterial.punchOpening1Length, width: this.model.baseMaterial.punchOpening1Width, originX: this.model.baseMaterial.punchOpening1OriginX, originY: this.model.baseMaterial.punchOpening1OriginY },
            { length: this.model.baseMaterial.punchOpening2Length, width: this.model.baseMaterial.punchOpening2Width, originX: this.model.baseMaterial.punchOpening2OriginX, originY: this.model.baseMaterial.punchOpening2OriginY },
            { length: this.model.baseMaterial.punchOpening3Length, width: this.model.baseMaterial.punchOpening3Width, originX: this.model.baseMaterial.punchOpening3OriginX, originY: this.model.baseMaterial.punchOpening3OriginY },
        ];

        const sideInfos: SideInfo[] = [];

        openings.forEach((currentOpening, i) => {
            for (let sideIndex = 0; sideIndex < NUMBER_OF_SIDES; sideIndex++) {
                const sidePosition = sideIndex + 1;

                sideInfos.push({
                    name: `Opening${i + 1}_Side${sidePosition}`,
                    index: sidePosition,
                    color: BASE_MATERIAL_COLOR,
                    sideDimension: this.getSideDimension(sideIndex, currentOpening),
                });
            }
        });

        return sideInfos;
    }

    private getSideDimension(sidePosition: SidePosition, opening: { length: number; width: number; originX: number; originY: number }): SideDimension {
        const { length, width, originX, originY } = opening;
        switch (sidePosition) {
            case SidePosition.Left:
                return { x: originX, y: originY + width / 2, length, rotation: 0 };
            case SidePosition.Top:
                return { x: originX - length / 2, y: originY, length: width, rotation: -Math.PI / 2 };
            case SidePosition.Right:
                return { x: originX, y: originY - width / 2, length, rotation: Math.PI };
            case SidePosition.Bottom:
                return { x: originX + length / 2, y: originY, length: width, rotation: Math.PI / 2 };
            default:
                throw new Error('Unknown side position');
        }
    }

    private createSideMesh(sideInfo: SideInfo): Mesh {
        const { name, color, sideDimension } = sideInfo;
        const mesh = this.cache.meshCache.create(name, () => CreatePlane(name, {}, this.scene));
        const materialName = name + 'Material';
        const material = this.cache.materialCache.create(materialName, () => new StandardMaterial(materialName, this.scene));

        material.diffuseColor = color;
        material.alpha = this.model.visibilityModel.TransparentConcrete ? TRANSPARENT_ALPHA : OPAQUE_ALPHA;

        mesh.setEnabled(true);
        mesh.material = material;
        mesh.scaling.x = sideDimension.length;
        mesh.scaling.y = this.model.baseMaterial.thickness;
        mesh.rotation.y = sideDimension.rotation;
        mesh.position.set(sideDimension.x, 0, sideDimension.y);

        // rotation
        mesh.parent = this.cache.meshCache.getCompressionMemberTransformNode(this.model.baseMaterial.compressionMemberId);

        return mesh;
    }
}
