import { Injectable } from '@angular/core';
import { Localization } from '@profis-engineering/module-template-library';

import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalizationService extends Localization {
    protected S3TranslationPath = 'pe-ui-sp/translations';
    protected reportTranslationsStartKeys = [
        'SP.',
        'Nolasoft.Hilti.SPS.',
        'Nolasoft.Hilti.Profis3.Report.EULA_1',
        'Nolasoft.Hilti.Profis3.Report.EULA_2',
        'Nolasoft.Hilti.Profis3.Report.EULA_TITLE'
    ];
    protected manifest = environment.manifest;
}
