import { PunchModel } from '../../../punch-gl-model';
import { CompressionMemberType } from '../compression-member';
import { PunchMeasurementPlaneRowData } from './punch-measurements';

export function SpanXMeasurement(model: PunchModel) : PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: 0,
                x2: model.baseMaterial.spanPosX,
                y1: -model.baseMaterial.spanNegY,
                y2: -model.baseMaterial.spanNegY,
                property: 'spanPosX',
                custom: false
            },
            {
                x1: -model.baseMaterial.spanNegX,
                x2: 0,
                y1: -model.baseMaterial.spanNegY,
                y2: 0,
                property: 'spanNegX',
                custom: false
            }
        ]
    };
}

export function SpanYMeasurement(model: PunchModel) : PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: 0,
                x2: model.baseMaterial.spanPosY,
                y1: model.baseMaterial.spanPosX,
                y2: model.baseMaterial.spanPosX,
                property: 'spanPosY',
                custom: false
            },
            {
                x1: -model.baseMaterial.spanNegY,
                x2: 0,
                y1: model.baseMaterial.spanPosX,
                y2: 0,
                property: 'spanNegY',
                custom: false
            }
        ]
    };
}

export function ThicknessMeasurement(model: PunchModel) : PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible,
        measurements: [
            {
                x1: - model.baseMaterial.thickness / 2,
                x2: model.baseMaterial.thickness / 2,
                y1: model.baseMaterial.spanPosX,
                y2: model.baseMaterial.spanPosX,
                property: 'thickness',
                custom: false
            }
        ]
    };
}


export function PunchLengthMeasurement(model: PunchModel) : PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible && model.baseMaterial.compressionMemberId != CompressionMemberType.Circular,
        measurements: [
            {
                x1: -model.baseMaterial.punchLength / 2,
                x2: model.baseMaterial.punchLength / 2,
                y1: -model.baseMaterial.punchWidth / 2,
                y2: -model.baseMaterial.punchWidth / 2,
                property: 'punchLength',
                custom: false
            }
        ]
    };
}

export function PunchWidthMeasurement(model: PunchModel) : PunchMeasurementPlaneRowData {
    return {
        enabled: model.visibilityModel.ConcreteDimensionsVisible && model.baseMaterial.compressionMemberId != CompressionMemberType.Circular,
        measurements: [
            {
                x1: -model.baseMaterial.punchWidth / 2,
                x2: model.baseMaterial.punchWidth / 2,
                y1: model.baseMaterial.punchLength / 2,
                y2: model.baseMaterial.punchLength / 2,
                property: 'punchWidth',
                custom: false
            }
        ]
    };
}
